import React from 'react'
import {Route, Routes} from "react-router-dom";

// page
import ScenarioInfo from '../../pages/scenarioManager/info/ScenarioInfo';
import AddScenario from '../../pages/scenarioManager/add/AddScenario';
import EditScenario from '../../pages/scenarioManager/edit/EditScenario';

const ScenarioManagerRoutes = () => {
  return (
    <Routes>
        <Route path="add" element={<AddScenario />} />
        <Route path="info" element={<ScenarioInfo />} />
        <Route path="edit" element={<EditScenario />} />
    </Routes>
  )
}

export default ScenarioManagerRoutes