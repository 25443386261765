import React from 'react'
import "./fileContainer.scss";

// UI component
import DocDesign from '../../../../components/UI/DocDesign/DocDesign';

const FileContainer = () => {
  return (
    <div className='fileContainer'>
        <span className="title">
            Asset Files
        </span>
        <div className="files">
            <DocDesign text="Project Config" />
            <DocDesign text="Asset Config" />
            <DocDesign text="Asset Data" />
            <DocDesign text="GeoJSON" />
        </div>
    </div>
  )
}

export default FileContainer