import React, { useEffect, useState, useRef } from 'react';
import "./map.scss";
import projectJson from '../../data/demo/projectConfig.json';
import "mapbox-gl/dist/mapbox-gl.css";

import mapboxgl from 'mapbox-gl'; 

mapboxgl.accessToken = 'pk.eyJ1IjoibmF0YWtyIiwiYSI6ImNscGpubmNobTAxbXIyaWxiaDVnYmNkdzIifQ.3W_ApfTl3qKBI1aSFA58EA';

const LoadingMap = ({ onAnimationEnd }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight / projectJson.display_grid.horizontal)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth / projectJson.display_grid.vertical)
    const [isStart, setIsStart] = useState(true);
   
    useEffect(() => {
        if (map.current) return; 
        const lng = 4.2927
        const lat = 50.8814
        const start = {
          center: [lng, lat],
          zoom: 1,
        };
        const end = {
          center: [lng, lat],
          zoom: 17.71,
          bearing: 15,
          // pitch: 130
          };

        map.current = new mapboxgl.Map({
              container: mapContainer.current,
              style: 'mapbox://styles/natakr/clsyia4nd003o01qp149ea0cs',
              ...start,
          });
  
        map.current.on('load', () => {

          const target = isStart ? end : start;
          setIsStart(!isStart)
          
          map.current.flyTo({
          ...target, // Fly to the selected target
          duration: 10000, // Animate over 12 seconds
          essential: true // This animation is considered essential with
          //respect to prefers-reduced-motion
          });
        });
        
        // Found this beauty at https://docs.mapbox.com/mapbox-gl-js/api/events/#evented#on
        map.current.once('moveend', () => {
          onAnimationEnd();
      });

        setScreenHeight(window.innerHeight / projectJson.display_grid.horizontal);
        setScreenWidth(window.innerWidth / projectJson.display_grid.vertical)
      }, [isStart, onAnimationEnd]);

    return (
          <div>
            <div className='sidebar'>Map is Loading</div>
            <div ref={mapContainer} className="map-container" style={{ height: screenHeight, width: screenWidth }}/>
          </div>
      );
}
export default LoadingMap;