import "./generalInfoPanel.scss";
import triangle from "../../../assets/demo/triangle.png";
import cloud from "../../../assets/demo/cloud.png";
import sun from "../../../assets/demo/sun.png";

export default function MeasurementCard({measurementDataObj}){
    const cardNamesMap = {el_power: "ELECTRIC GRID", th_power: 'THERMAL GRID', gas_power: 'GAS GRID'}
    
    return (
        <div className="measurementCardWrapper">
            <div className="cardName">
                {cardNamesMap[measurementDataObj.energy_type]}
            </div>
            <div className="valuesWrapper">
    
                <div className='monetary'>
                    <img src={triangle} alt="money" width="40" height="40"/>
                    <div style = {{width:"40", height:"20"}}>N/A</div>
                </div>

                <div className='pollution'>
                    <img src={cloud} alt="pollution" width="40" height="40"/>
                    <div style = {{width:"40", height:"20"}}>N/A</div>
                </div>
                <div className='energy'>
                    <img src={sun} alt="energy" width="40" height="40"/>
                    <div style = {{width:"40", height:"20"}}>{measurementDataObj.energy}</div>
                </div>
            </div>
        </div>
    )

}