export default function Co2Svg({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="l1"
      height="6vh"
      width="6vh"
      viewBox="0 0 50 50"
    >
      <path
        fill={color}
        d="M43.04 17.31c.21-.81.32-1.64.34-2.48.12-6-4.67-10.97-10.67-11.09-1.48-.03-2.91.23-4.27.78a8.598 8.598 0 0 0-5.45-2.09c-2.29-.05-4.48.81-6.14 2.41s-2.6 3.74-2.64 6.04c0 .34 0 .69.05 1.04-2.65 0-5.16 1.01-7.08 2.86-1.97 1.9-3.09 4.45-3.14 7.18-.09 4.53 2.81 8.57 7.09 9.94a9.814 9.814 0 0 0 8.85 5.92h.2c1.49 0 2.92-.33 4.26-.97.4-.19.57-.68.38-1.08a.815.815 0 0 0-1.08-.38 8.24 8.24 0 0 1-3.72.81 8.217 8.217 0 0 1-7.53-5.26.809.809 0 0 0-.53-.49 8.615 8.615 0 0 1-6.29-8.47c.04-2.3.98-4.45 2.64-6.05a8.591 8.591 0 0 1 6.15-2.41c.24 0 .47.02.7.04.26.02.51-.07.68-.27.17-.19.24-.46.18-.71-.13-.57-.19-1.14-.18-1.69.04-1.87.8-3.61 2.15-4.91 1.35-1.3 3.11-1.98 4.99-1.95 1.78.03 3.47.75 4.76 2 .24.23.6.3.9.16 1.28-.59 2.64-.87 4.06-.85a9.23 9.23 0 0 1 6.5 2.84 9.176 9.176 0 0 1 2.59 6.6c-.02.84-.15 1.67-.39 2.48-.08.27-.01.56.17.77 1.71 1.91 2.63 4.38 2.58 6.94-.11 5.58-4.72 10.01-10.31 9.92a9.93 9.93 0 0 1-4.25-1.03c-.4-.2-.88-.03-1.08.37-.2.4-.03.88.37 1.08 1.54.76 3.2 1.16 4.93 1.19 6.46.13 11.83-5.04 11.96-11.5.05-2.82-.9-5.55-2.7-7.71Z"
        className="cls-1" />
      <path
        fill={color}
        d="m27.38 30.36 4.01-2.32c.39-.22.52-.72.29-1.1a.794.794 0 0 0-1.1-.29l-3.2 1.85V12.69c0-.45-.36-.81-.81-.81s-.81.36-.81.81v12.83l-3.07-1.78a.809.809 0 0 0-1.1.29c-.22.39-.09.88.29 1.1l3.88 2.24v18.2h-9.7v1.61h20.69v-1.61h-9.38V30.35Z"
        className="cls-1" />
    </svg>
  )
}

