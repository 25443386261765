import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./mapVisualizer.scss";
import projectJson from "../../data/demo/projectConfig.json";
import "mapbox-gl/dist/mapbox-gl.css";
import axios from "axios";
import vub_evergi_logo from '../../assets/images/vub_evergi_logo_dark.png';

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = process.env.REACT_APP_MAPBOXGL_TOKEN
const API_URL = "/api/v1";

const MapVisualizer = () => {
  const navigate = useNavigate();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(4.2927);
  const [lat, setLat] = useState(50.8814);
  const [zoom, setZoom] = useState(12);
  const [screenHeight, setScreenHeight] = useState(
    window.innerHeight / projectJson.display_grid.horizontal
  );
  const [screenWidth, setScreenWidth] = useState(
    window.innerWidth / projectJson.display_grid.vertical
  );
  const [scenarios, setScenarios] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState("");
  const [selectedAnimation, setSelectedAnimation] = useState("filled circles");
  const [endGeoData, setEndGeoData] = useState(null)
  const [configDataLoaded, setConfigDataLoaded] = useState(false)
  const [errorConfigData, setErrorConfigData] = useState(false)
  /*
  Uncomment if we want animation selection 
  const animations = [
    {
      label: "Charging Lines",
      value: "charging lines"
    },
    {
      label: "Snake Lines",
      value: "snake lines"
    },
    {
      label: "Circles",
      value: "circles"
    },
    {
      label: "Filled Circles",
      value: "filled circles"
    }
  ]
  */


  const handleLoadMap = () => {
    if (selectedScenario !== "" & endGeoData!==null) {
      navigate(`/map/player?id=${selectedScenario}&animation=${selectedAnimation}&lng=${endGeoData.lng}&lat=${endGeoData.lat}&zoom=${endGeoData.zoom}&bearing=${endGeoData.bearing}`)
    }
  }

  useEffect(() => {
    const getEndCoord = async () => {
      try {
        const configResponse = await axios.get(`${API_URL}/scenario/detail/projectconfig/${selectedScenario}`);
        if (configResponse.status === 200) {
          const siteCoordinates = {
            lng: configResponse.data.projectConfig.centerLng,
            lat: configResponse.data.projectConfig.centerLat,
            zoom: configResponse.data.projectConfig.zoom,
            bearing: configResponse.data.projectConfig.mapBearing
          }
          setEndGeoData(siteCoordinates)
          setErrorConfigData(false)
          setConfigDataLoaded(true)
        }
      } catch (error) {
        setErrorConfigData(true)
      }
    }
    if (selectedScenario){
      getEndCoord()
    }
  }, [selectedScenario])

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v11",
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });

    map.current.on("load", () => { });

    setScreenHeight(window.innerHeight / projectJson.display_grid.horizontal);
    setScreenWidth(window.innerWidth / projectJson.display_grid.vertical);
  }, [lat, lng, zoom]);

  useEffect(() => {
    const getScenarios = async () => {
      try {
        const res = await axios.get(`${API_URL}/scenario/list`);

        if (res.data.status === 200 && res.data.success === true) {
          let scenariolist = [];
          if (res.data.scenarios.length > 0) {
            res.data.scenarios.forEach((scene) => {

              scenariolist.push({
                name: scene.name,
                description: scene.description,
                id: scene._id,
              });
            });
            setSelectedScenario(scenariolist[0].id)
            setScenarios(scenariolist)
          }
        }
      } catch (error) {
        if (
          error.response.data.status === 401 &&
          error.response.data.success === false
        ) {
          navigate("/");
        }
      }
    };

    getScenarios();
  }, [navigate]);

  return (
    <div>
      <div className="toDemoWrapper">
        <button
          className="toDemoBtn"
          onClick={() => {
            navigate("/map/demo");
          }}
        >
          {" "}
          Proceed to Demo
        </button>
      </div>

      <div className="scenarioSelectionWrapper">
        <div className="scenarioPicker">
          <div>Select Scenario</div>
          <div className="scenarioDropDownContainer" key={1}>
            <select
              value={selectedScenario}
              onChange={(e) => {
                setConfigDataLoaded(false)
                setSelectedScenario(e.target.value)
              }}
            >
              {scenarios.map((scenario) => (
                <option key={scenario.id} value={scenario.id}>
                  {scenario.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* Uncomment if we want animation selection 
        <div className="animationPicker">
          <div>Select Animation</div>
          <div className="animationDropDownContainer">
            <select
              value={selectedAnimation}
              onChange={(e) => setSelectedAnimation(e.target.value)}
            >
              {animations.map((animation, index) => (
                <option key={index} value={animation.value}>
                  {animation.label}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        {errorConfigData? <div>Project Config File Error</div> : configDataLoaded ? <button
          className="toScenarioBtn"
          onClick={() => {
            handleLoadMap()
          }}
        >
          Go to Map
        </button>: <></>}
      </div>

      <div
        ref={mapContainer}
        className="map-container"
        style={{ height: screenHeight, width: screenWidth }}
      />
      <div className="vubEvergiLogoWrapper">
          <img src={vub_evergi_logo} alt='vub-evergi-logo' width='134px'/>
      </div>
    </div>
  );
};
export default MapVisualizer;
