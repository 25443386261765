import React, { useEffect, useState } from "react";
import "./editScenario.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

// components
import AppBar from "../../../components/UI/AppBar/AppBar";

import { BsFileEarmarkPlus } from "react-icons/bs";
import { LuFileJson } from "react-icons/lu";
import { BsFiletypeCsv } from "react-icons/bs";

const API_URL = "/api/v1";

const EditScenario = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id") || "";

  const [inputdata, setInputdata] = useState({
    _id: id,
    name: "",
    description: "",
    project_config_id: "",
    asset_config_id: "",
    asset_data_id: "",
    geojson_id: "",
  });

  const [inputFiles, setInputFiles] = useState({
    project_config: {
      filename: null,
      file: null,
      content: null,
    },
    config: {
      filename: null,
      file: null,
      content: null,
    },
    data: {
      filename: null,
      file: null,
      content: null,
    },
    geojson: {
      filename: null,
      file: null,
      content: null,
    },
  });

  const [errorParams, setErrorParams] = useState([]);

  const handleNavPreVPage = () => {
    navigate(`/scenariomanager/info?id=${inputdata._id}`);
  };

  const monitorInputChange = (ele) => {
    let elem = document.getElementById(ele);
    if (elem) {
      elem.style.border = "none";
    }
  };

  const handleFileLoad = (e, filetype) => {
    let filename = "";
    const file = e.target.files[0];

    if (file) {
      filename = file.name;
      if (filetype === "projectConfig") {
        setInputFiles({
          ...inputFiles,
          project_config: {
            filename,
            file: "loaded",
            content: file,
          },
        });
      } else if (filetype === "config") {
        setInputFiles({
          ...inputFiles,
          config: {
            filename,
            file: "loaded",
            content: file,
          },
        });
      } else if (filetype === "data") {
        setInputFiles({
          ...inputFiles,
          data: {
            filename,
            file: "loaded",
            content: file,
          },
        });
      } else if (filetype === "geojson") {
        setInputFiles({
          ...inputFiles,
          geojson: {
            filename,
            file: "loaded",
            content: file,
          },
        });
      }
    }
  };

  const validateForm = () => {
    let errorlist = [];
    if (inputdata.name === "" || inputdata.name.length < 2) {
      errorlist.push({
        path: "name",
        msg: "Scenario name should be atleast 2 characters",
      });
    }
    if (inputdata.description === "" || inputdata.description.length < 10) {
      errorlist.push({
        path: "description",
        msg: "Scenario description should be atleast 10 characters",
      });
    }

    setErrorParams(errorlist);
    if (errorlist.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleFormSubmit = async () => {
    try {
      let validation = validateForm();
      if (validation === false) {
        return false;
      } else {
        const formData = new FormData();
        formData.append("name", inputdata.name);
        formData.append("description", inputdata.description);

        if (inputFiles.project_config.file !== null) {
          formData.append(
            "project_config_file",
            inputFiles.project_config.content
          );
        }
        if (inputFiles.config.file !== null) {
          formData.append("asset_config_file", inputFiles.config.content);
        }
        if (inputFiles.data.file !== null) {
          formData.append("asset_data_file", inputFiles.data.content);
        }
        if (inputFiles.geojson.file !== null) {
          formData.append("geojson_data_file", inputFiles.geojson.content);
        }
        const res = await axios.post(`${API_URL}/scenario/edit/${inputdata._id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if(res.data.status === 200 && res.data.success === true){
            navigate(`/scenariomanager/info?id=${inputdata._id}&action=edit&status=success`)
          }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (errorParams.length > 0) {
      errorParams.forEach((err) => {
        let ele = document.getElementById(err.path);
        if (ele) {
          ele.style.border = "1px solid red";
        }
      });
    }
  }, [errorParams]);

  useEffect(() => {
    if (id !== "") {
      const fetchScenarioInfo = async () => {
        try {
          const res = await axios.get(`${API_URL}/scenario/info/${id}`);
          if (res.data.status === 200 && res.data.success === true) {
            setInputdata(res.data.scenario);
          }
        } catch (error) {
          if (
            error.response.data.status === 400 &&
            error.response.data.success === false
          ) {
            navigate("/scenariomanager");
          }
        }
      };
      fetchScenarioInfo();
    }
  }, [id]);

  return (
    <div className="editScenarioContainer">
      <AppBar
        title="Edit Scenario"
        pageType="action"
        actionFunc={handleNavPreVPage}
        icon={null}
        text={null}
      />
      <form
        className="formContainer"
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <div className="fileInputContainer">
          <label
            className="card"
            htmlFor="projConfFile"
            id="project_config_label"
          >
            {inputFiles.project_config.file === null ? (
              <>
                <BsFileEarmarkPlus className="icon" />
                <span className="label">(JSON)</span>
                <span className="label">Upload Project Config File</span>
              </>
            ) : (
              <>
                <LuFileJson className="icon" />
                <span className="label">
                  {inputFiles.project_config.filename}
                </span>
              </>
            )}
          </label>
          <input
            hidden
            type="file"
            accept=".json"
            id="projConfFile"
            onChange={(e) => {
              handleFileLoad(e, "projectConfig");
              monitorInputChange("project_config_label");
            }}
          />
          <label className="card" htmlFor="config" id="config_label">
            {inputFiles.config.file === null ? (
              <>
                <BsFileEarmarkPlus className="icon" />
                <span className="label">(CSV)</span>
                <span className="label">Upload Config File</span>
              </>
            ) : (
              <>
                <BsFiletypeCsv className="icon" />
                <span className="label">{inputFiles.config.filename}</span>
              </>
            )}
          </label>
          <input
            hidden
            type="file"
            accept=".csv"
            id="config"
            onChange={(e) => {
              handleFileLoad(e, "config");
              monitorInputChange("config_label");
            }}
          />
          <label className="card" htmlFor="data" id="data_label">
            {inputFiles.data.file === null ? (
              <>
                <BsFileEarmarkPlus className="icon" />
                <span className="label">(CSV)</span>
                <span className="label">Upload Data File</span>
              </>
            ) : (
              <>
                <BsFiletypeCsv className="icon" />
                <span className="label">{inputFiles.data.filename}</span>
              </>
            )}
          </label>
          <input
            hidden
            type="file"
            accept=".csv"
            id="data"
            onChange={(e) => {
              handleFileLoad(e, "data");
              monitorInputChange("data_label");
            }}
          />
          <label className="card" htmlFor="geojson" id="geojson_label">
            {inputFiles.geojson.file === null ? (
              <>
                <BsFileEarmarkPlus className="icon" />
                <span className="label">(GEOJSON)</span>
                <span className="label">Upload GeoJSON File</span>
              </>
            ) : (
              <>
                <LuFileJson className="icon" />
                <span className="label">{inputFiles.geojson.filename}</span>
              </>
            )}
          </label>
          <input
            hidden
            type="file"
            accept=".geojson"
            id="geojson"
            onChange={(e) => {
              handleFileLoad(e, "geojson");
              monitorInputChange("geojson_label");
            }}
          />
        </div>
        <div className="inputHolder">
          <span>Scenario Name</span>
          <input
            type="text"
            placeholder="Enter scenario name"
            id="name"
            value={inputdata.name}
            onChange={(e) => {
              setInputdata({
                ...inputdata,
                name: e.target.value,
              });
              monitorInputChange("name");
            }}
          />
        </div>
        <div className="textareaHolder">
          <span>Scenario Description</span>
          <textarea
            rows="10"
            placeholder="Enter scenario description"
            id="description"
            onChange={(e) => {
              setInputdata({
                ...inputdata,
                description: e.target.value,
              });
              monitorInputChange("description");
            }}
            value={inputdata.description}
          ></textarea>

          <div className="btnContainer">
            <button type="button" onClick={handleNavPreVPage}>
              Cancel
            </button>
            <button type="submit">Save</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditScenario;
