import React from 'react'
import "./home.scss"


// components
import Login from "../../components/Login/Login";

const Home = () => {
  return (
    <div className='homeContainer'>
        <div className="loginContainer">
          <Login />
        </div>
    </div>
  )
}

export default Home