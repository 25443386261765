import React, { useState } from 'react';
import "./nodeInfoPanel.scss";
import angle from "../../../assets/demo/angle.png";
import angleClose from "../../../assets/demo/angleclose.png";
// import GeneralPanelClosed from './generalInfoPanelClosed';
// import GeneralPanelOpen from './generalnfoPanelDetails';

export default function NodeInfoPanel({isPanelOpen, polygonCoord, handleOpenClose, panelPosition, node}){
    const [isOpen, setIsOpen] = useState(isPanelOpen)

    function toggleOpenClose(){
        setIsOpen(!isOpen)
        handleOpenClose(isOpen)
    }

    return(
        <div className={'nodeInfoPanelWrapper'+panelPosition}>
            <div className='nodeWrapper'>
                <p>{node}</p>
                <svg name="viewport" width='60' height='50' xmlns="http://www.w3.org/2000/svg">
                    <polygon points={polygonCoord} fill="none" stroke="black" strokeWidth={3} />
                </svg>
            </div>
            <div className="openCloseButton" onClick={toggleOpenClose}>
               {isOpen ? <img src={angleClose} alt="pollution" width="20" height="60"/> : <img src={angle} alt="pollution" width="20" height="60"/>}
           </div>
        </div>
    )

}