import React, { useState } from "react";
import "./gridSelectorNew.scss";

const GridSelector = ({ options, defaultValue, onSelect, projectConfig }) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultValue);

  const toggleOption = (option) => {
    const isSelected = selectedOptions.includes(option);
    let newSelectedOptions;
    if (isSelected) {
      newSelectedOptions = selectedOptions.filter(
        (selectedOption) => selectedOption !== option
      );
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(newSelectedOptions);
    onSelect(option);
  };

  return (
    <div className="gridIconCard">
      <span>Grids</span>
      <div className="gridIconContainer">
        {options.map((option) => (
          <div
            key={option.value}
            className={`iconCard ${selectedOptions.includes(option.value) ? "active" : ""
              }`}
            onClick={() => toggleOption(option.value)}
            style={{
              backgroundColor:
                projectConfig.grid_color_power_type[option.value],
            }}
          >
            <img className="icon" src={option.icon} alt={option.value} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GridSelector;
