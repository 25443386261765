export default function EnergyBalanceSvg({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="l2"
      height="6vh"
      width="6vh"
      viewBox="0 0 50 50"
    >
      <path
      fill={color}
        d="M18.63 7.9v32.07h1.63V7.9l2.46 2.65.72-.67-3.99-4.29-3.99 4.29.71.67 2.46-2.65zM30.05 12.76v24.9l-2.47-2.65-.71.67 3.99 4.29 3.99-4.29-.71-.67-2.47 2.65v-24.9h-1.62z"
        className="cls-1" />
      <path
      fill={color}
        d="M25.29 5.21c-.45 0-.81.36-.81.81s.36.81.81.81c10.55 0 19.13 8.58 19.13 19.13s-8.58 19.13-19.13 19.13S6.16 36.51 6.16 25.96c0-4.93 1.87-9.61 5.27-13.19.31-.33.3-.84-.03-1.15a.81.81 0 0 0-1.15.03 20.657 20.657 0 0 0-5.72 14.31c0 11.44 9.31 20.75 20.75 20.75s20.75-9.31 20.75-20.75-9.3-20.75-20.74-20.75Z"
        className="cls-1" />
    </svg>
  )
}
