import React from "react";
import "./addScenario.scss";
import { useNavigate } from "react-router-dom";

// component
import AppBar from "../../../components/UI/AppBar/AppBar";
import ScenarioForm from "../../../components/ScenarioForm/ScenarioForm";



const AddScenario = () => {
  const navigate = useNavigate();
  const inputdata = {
    name: "",
    description:""
  }

  const handleNavPreVPage = () =>{
    navigate("/scenariomanager");
  }

  return (
    <div className="addScenarioContainer">
      
      <AppBar 
        title="Add Scenario"
        pageType="action"
        actionFunc={handleNavPreVPage}
        icon={null}
        text={null}
      />
     <ScenarioForm edit={false} inpdata={inputdata} />
      
    </div>
  );
};

export default AddScenario;
