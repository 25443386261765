import React, { useState, useEffect } from "react";
import "./dataTable.scss";

const DataTable = ({datacolumn,datasource, handleClick}) => {
  const [dataColumn, setDataColumn] = useState([])
  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    setDataColumn(datacolumn)
    setDataSource(datasource)
  },[datacolumn, datasource]);
  return (
    <table className="dataTable">
      <thead className="thead">
        <tr>
          <td className="rowCount">#</td>
          {dataColumn.map((child, index) => (
            <td key={index} className="tableColumn">
              {child}
            </td>
          ))}
        </tr>
      </thead>
      <tbody className="tableBodyContainer">
        {dataSource.map((child, index) => (
          <tr
            key={index}
            onClick={() => {
              handleClick(child[0].value);
            }}
          >
            {child.map((data) =>
              data.key !== "id" ? (
                <td key={data.key} className={data.key === 0 ? "index" : ""}>
                  {data.value}
                </td>
              ) : ("")
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;
