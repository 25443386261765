import { useSprings, animated } from 'react-spring';

 export function LinesComponentMultisprings({circlesDataArr: timeDataArr, screenHeight, screenWidth}){

    var springs = useSprings(
        timeDataArr.length,
        timeDataArr.map((route)=>({
          from: { x2: route.from.x, y2: route.from.y}, // Initial coordinates for each set of points
          to: { x2: route.to.x, y2: route.to.y}, // Final coordinates for each set of points
          config: { duration: route.duration}, // Duration for each set of points (adjust as needed)
          loop: true, // Enable looping animation
        })) 
    )
  

    const renderAnimatedCircles = () => {
      return springs.map((props, index) =>{  return (
        <animated.line key={index} {...props} x1={timeDataArr[index].from.x} y1={timeDataArr[index].from.y} 
                            stroke={timeDataArr[index].color} strokeWidth={timeDataArr[index].size} />
      )});
    };
  
    return (<svg pointerEvents="none" width={screenWidth} height={screenHeight} style={{ position: 'absolute', zIndex: 99 }}>
      {renderAnimatedCircles()}
    </svg>)
  
  }


  