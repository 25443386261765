import { useSprings, animated } from 'react-spring';


export function FilledCirclesComponent({ animatedItemsDataArr, screenHeight, screenWidth, selectedFlows }) {
  const animatedItems = animatedItemsDataArr.filter((route) => selectedFlows.includes(route.route_power_type))
  var springs = useSprings(
    animatedItems.length,
    animatedItems.map((route) => ({
      from: { cx: route.from.x, cy: route.from.y, strokeOpacity: 1 }, // Initial coordinates for each set of points
      to: { cx: route.to.x, cy: route.to.y, strokeOpacity: 0.4 }, // Final coordinates for each set of points
      config: { duration: route.duration }, // Duration for each set of points (adjust as needed)
      loop: true, // Enable looping animation
    }))
  )


  const renderAnimatedCircles = () => {
    return springs.map((props, index) => {
      return (
        <animated.circle key={index} {...props} r={animatedItems[index].size} stroke={animatedItems[index].color} strokeWidth={2} fill={animatedItems[index].color} />
      )
    });
  };

  return (<svg pointerEvents="none" width={screenWidth} height={screenHeight} style={{ position: 'absolute', zIndex: 99 }}>
    {renderAnimatedCircles()}
  </svg>)
}
