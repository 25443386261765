import React, { useState, useEffect } from "react";
import "./scenarioInfo.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Modal, notification } from "antd";

// component
import AppBar from "../../../components/UI/AppBar/AppBar";
import FileContainer from "./FileContainer/FileContainer";

// icons
import { MdDeleteOutline } from "react-icons/md";
import { MdOutlineModeEdit } from "react-icons/md";

const API_URL = "/api/v1";

const ScenarioInfo = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [api, contextHolder] = notification.useNotification();

  // query parameters do not change the order and the placement here
  const id = searchParams.get("id");
  const action = searchParams.get("action") || "";
  const status = searchParams.get("status") || "";

  const [modalOpen, setModalOpen] = useState(false);

  const [scenarioInfo, setScenarioInfo] = useState({
    _id: id,
    name: "",
    description: "",
    project_config_id: "",
    asset_config_id: "",
    asset_data_id: "",
    geojson_id: "",
  });

  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description
    });
  };

  const navPrevPage = () => {
    navigate("/scenariomanager");
  };

  const handleDeleteScenario = async () => {
    try {
      const res = await axios.delete(`${API_URL}/scenario/delete/${id}`);
      if (res.data.status === 200 && res.data.success === true) {
        navigate("/scenariomanager?action=delete&status=success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id !== null) {
      const fetchScenarioInfo = async () => {
        try {
          const res = await axios.get(`${API_URL}/scenario/info/${id}`);
          if (res.data.status === 200 && res.data.success === true) {
            setScenarioInfo(res.data.scenario);
          }
        } catch (error) {
          if (
            error.response.data.status === 400 &&
            error.response.data.success === false
          ) {
            navigate("/scenariomanager");
          }
        }
      };
      fetchScenarioInfo();
    }
  }, [id]);

  useEffect(() => {
    if (action !== "" && status !== "") {
      openNotificationWithIcon('success',
        'Scenrio Update',
        'Scenario edited successfully.'
      )
    }
  }, [action]);

  return (
    <div className="scenarioInfo">
      {contextHolder}
      <Modal
        title="Confirm Delete"
        centered
        open={modalOpen}
        onOk={() => {
          handleDeleteScenario();
          setModalOpen(false);
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
        okButtonProps={{
          type: "primary",
          style: {
            backgroundColor: "#E2395C",
          },
        }}
        okText="Delete"
        cancelText="Cancel"
        cancelButtonProps={{
          style: {
            border: "1px solid #BEBEBE",
            color: "#313335",
          },
        }}
      >
        <p>Do you want to delete this scenario?</p>
      </Modal>

      <AppBar
        title="Scenario Detail"
        pageType="action"
        actionFunc={navPrevPage}
        icon={null}
        text={null}
      />
      <div className="infoContainer">
        <div className="titleBar">
          <span className="name">{scenarioInfo.name}</span>
          <div className="btnContainer">
            <button
              className="delBtn"
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <MdDeleteOutline className="icon" /> Delete Scenario
            </button>
            <button
              className="editBtn"
              onClick={() => {
                navigate(`/scenariomanager/edit?id=${scenarioInfo._id}`);
              }}
            >
              <MdOutlineModeEdit className="icon" /> Edit Scenario
            </button>
          </div>
        </div>
        <FileContainer />
        <span className="label">Description</span>
        <span className="description">{scenarioInfo.description}</span>
      </div>
    </div>
  );
};

export default ScenarioInfo;
