export default function MonetarySvg({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="l3"
      height="6vh"
      width="6vh"
      viewBox="0 0 50 50"
      // height={50}
      // width={50}
      // viewBox="0 0 50 50" 
    >
      <path
        fill={color}
        stroke={color}
        d="M25.03 34.38c-11.94 0-21.65-4.93-21.65-10.98s9.71-10.98 21.65-10.98 21.65 4.93 21.65 10.98-9.71 10.98-21.65 10.98Zm0-21.34c-11.59 0-21.02 4.64-21.02 10.35s9.43 10.35 21.02 10.35 21.02-4.64 21.02-10.35-9.43-10.35-21.02-10.35Z"
        className="cls-2" />
      <path
        fill={color}
        stroke={color}
        d="M25.03 39.81c-11.94 0-21.65-4.72-21.65-10.52V23.4h.63v5.89c0 5.46 9.43 9.9 21.02 9.9s21.02-4.44 21.02-9.9V23.4h.63v5.89c0 5.8-9.71 10.52-21.65 10.52Z"
        className="cls-2" />
      <path
        fill={color}
        stroke={color}
        strokeWidth="0.5"
        d="M14.42 22.51c-.35 0-.63-.06-.85-.19s-.33-.3-.33-.52c0-.18.11-.34.33-.48.22-.14.51-.2.85-.2h13.24c.35 0 .63.07.85.2.22.14.33.3.33.48 0 .22-.11.39-.33.52s-.51.19-.85.19H14.42Zm0 2.92c-.35 0-.63-.07-.85-.2s-.33-.31-.33-.54c0-.18.11-.34.33-.47s.51-.2.85-.2h11.51c.35 0 .63.07.85.2s.33.29.33.47c0 .23-.11.41-.33.54s-.51.2-.85.2H14.42Zm11.07 4.47c-1.34 0-2.59-.17-3.75-.5-1.16-.33-2.18-.79-3.04-1.38-.87-.59-1.54-1.28-2.03-2.07-.49-.79-.73-1.65-.73-2.57s.24-1.76.73-2.54c.49-.79 1.16-1.47 2.03-2.06.87-.59 1.88-1.05 3.03-1.39 1.15-.34 2.41-.5 3.76-.5 1.27 0 2.36.11 3.28.33.92.22 1.84.57 2.76 1.07.13.06.24.13.31.2s.12.14.15.21c.02.07.04.15.04.25 0 .21-.11.38-.33.51-.22.14-.49.21-.8.23-.31.02-.62-.05-.92-.2-.61-.35-1.24-.61-1.89-.8-.65-.18-1.51-.28-2.59-.28-.97 0-1.89.13-2.75.39-.86.26-1.61.62-2.26 1.07-.65.46-1.16.98-1.52 1.58-.36.6-.54 1.24-.54 1.93s.18 1.34.54 1.94c.36.6.87 1.13 1.52 1.58.65.46 1.41.81 2.26 1.06.86.25 1.77.38 2.75.38.84 0 1.64-.09 2.39-.28.75-.19 1.47-.45 2.17-.79.3-.14.59-.2.88-.19.29.02.53.09.73.23.2.14.3.32.3.56 0 .11-.03.21-.1.32-.07.1-.17.2-.3.28-.89.47-1.84.82-2.86 1.06-1.02.24-2.08.36-3.21.36Z"
      />
    </svg>
  )
}
