import React from 'react'
import "./docDesign.scss";


const DocDesign = ({text}) => {
  return (
    <div className='docDesign'>
      <div className="container">
      <hr className='customHr' />
        <hr className='customHr' />
        <hr className='customHr' />
        <hr className='customHr' />
        <hr className='customHr' />
        <hr className='customHr' />
        {text}
        <hr className='customHr' />
        <hr className='customHr' />
        <hr className='customHr' />
        <hr className='customHr' />
        <hr className='customHr' />
        <hr className='customHr' />
      </div>
        
    </div>
  )
}

export default DocDesign