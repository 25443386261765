import { Consts } from "./consts";
import { lineIntersect, length, lineString } from '@turf/turf';
import { Combination } from 'js-combinatorics';

export function defineImageMarkerForCpocNodes(imgElement, id, cpocIconsObj){
    imgElement.src = cpocIconsObj[id]
    imgElement.setAttribute('width', `${Consts.default_cpoc_circle_radius*2}px`);
    imgElement.setAttribute('height', `${Consts.default_cpoc_circle_radius*2}px`);
    imgElement.className = 'marker';
}

export function getPlaceholderNodesIconDataSource(geoData, config, projectConfig){
    const placeHolderNodes = config.filter((item)=>item.parameter==='node_type' && !['building', null].includes(item.value))
    // const placeHolderNodes = [{value: 'chp', node: 'node24'}, {value: 'pv', node: 'node25'}, 
    //                           {value: 'wind', node: 'node26'}, {value: 'battery', node: 'node27'}, {value: 'evchargers', node: 'node28'} ]
    const allPolygons = geoData.features.filter((feature)=>feature.geometry.type==='Polygon');

    // icons in mapbox should have the same names as node+type
    var iconsPointsArr = []
    for (let nodeConf of placeHolderNodes){
        const polygon = allPolygons.find((item)=>item.properties.node===nodeConf.node)

        const combinationsOfVerteces = new Combination(polygon.geometry.coordinates[0], 2).toArray();
        const linesArr = combinationsOfVerteces.map((combination)=>lineString([combination[0], combination[1]]))
        var diagonalsArr = []
        const distancesArr = Array.from(new Set(linesArr.map((ln)=>length(ln))).values())
        const twoLongestDistancesArr = distancesArr.sort((a, b) => a - b).reverse().slice(0, 2)
        for (let ln of linesArr){
            const dist = length(ln)
            if (twoLongestDistancesArr.includes(dist)){
                diagonalsArr.push(ln)
            }
        }
        var iconPoint = lineIntersect(diagonalsArr[0], diagonalsArr[1])
        iconPoint.features[0].properties['icon']=nodeConf.value
        // iconPoint.features[0].properties['icon']="wind"
        iconPoint.features[0].properties['rotation']=projectConfig.mapBearing
        iconPoint.features[0].properties['size']=projectConfig.placeholder_nodes_icon_size ? projectConfig.placeholder_nodes_icon_size : 1.2
        iconsPointsArr.push({id: `${nodeConf.node_type}_${nodeConf.node}`, node: nodeConf.node, iconPoint: iconPoint})
    }
    return iconsPointsArr
  }

  export function getLayerForPlaceholderNodesIcons(id){
    const layer = {
        'id': id,
        'source': id,
        'slot': 'top',
        'type': 'symbol',
        'layout': {
            'icon-image': ['get', 'icon'],
            'icon-size': ['get', 'size'],
            'icon-rotate': ['get', 'rotation'],
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true
        },
      }
    return layer
}

// export function getLayerForPlaceholderNodesIcons(id){
//     const layer = {
//         'id': id,
//         'source': id,
//         'type': 'circle',
//         'slot': 'top',
//         'paint': {
//           'circle-radius': Consts.default_cpoc_circle_radius,
//           'circle-color': 'red',
//           "circle-opacity": 1,
//           "circle-stroke-width": 1,
//           "circle-stroke-color": ['get', 'color']
//         }
//       }
//     return layer
// }

export function getAllBuidingsForLayers(geoData){
    const allPolygons = geoData.features.filter((feature)=>feature.geometry.type==='Polygon');
    const nodesArr = Array.from(new Set(allPolygons.map((poly)=>poly.properties.node)).values());
    
    var buildingsArr = []

    for (let node of nodesArr){
        const nodeData = allPolygons.find((poly)=>poly.properties.node===node);
        // console.log(nodeData)
        const building = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': nodeData.geometry.coordinates
                    },
                    'properties': {
                        'node': nodeData.properties.node,
                        'color': Consts.default_building_outline_color
                    }
                }
            ]
        }
        const buildingObj = {id: nodeData.properties.node, polygon: building}
        buildingsArr.push(buildingObj)
    }
    return buildingsArr
  }

export function getAllCpocPoints(geoData, projectConfig){
    const allCpocNodes = geoData.features.filter((feature)=>feature.geometry.type==='Point');
    const nodesArr = Array.from(new Set(allCpocNodes.map((cpoc)=>cpoc.properties.node)).values());

    const allCpocArr = []

    for (let node of nodesArr){
        const nodeData = allCpocNodes.find((cpoc)=>cpoc.properties.node===node);
        // console.log(nodeData)
        const cpocNode = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': nodeData.geometry.coordinates
                    },
                    'properties': {
                        'node': nodeData.properties.node,
                        'color': projectConfig.cpoc_color_power_type[nodeData.properties.power_type]
                    }
                }
            ]
        }
        const cpocObj = {id: nodeData.properties.node, cpoc: cpocNode}
        allCpocArr.push(cpocObj)
    }
    return allCpocArr
}

export function getInitialCpocStartingLayer(id){
    const layer = {
        'id': `${id}_inner`,
        'source': id,
        'type': 'circle',
        'slot': 'top',
        'paint': {
          'circle-radius': Consts.default_cpoc_circle_radius,
          'circle-color': ['get', 'color'],
          "circle-opacity": 1,
          "circle-stroke-width": 1,
          "circle-stroke-color": ['get', 'color']
        }
      }
    return layer
}

export function getInitialCpocOuterLayer(id){
    const layer = {
        'id': id,
        'source': id,
        'type': 'circle',
        'slot': 'top',
        'paint': {
          'circle-radius': Consts.default_cpoc_circle_outer_radius,
          'circle-color': ['get', 'color'],
          "circle-opacity": 0,
          "circle-stroke-width": Consts.default_cpoc_outer_circle_width,
          "circle-stroke-color": ['get', 'color']
        }
      }
    return layer
}

export function getMainGridLines(geoData, projectConfig){
    const filteredRoutes = geoData.features.filter((feature)=>feature.properties.route==='grid');
    // console.log(filteredRoutes)
    var updatedRoutesArr = []
    for (let route of filteredRoutes){
        const color = projectConfig.grid_color_power_type[route.properties.power_type]
        route.properties['color'] = color
        updatedRoutesArr.push(route)
    }
    return {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': updatedRoutesArr
        } 
    }
}

// for switching between grids
export function getMainGridLineForAnimatedFlows(geoData, projectConfig){
    var gridsArray = []
    for (let power_type of projectConfig.animated_flows){
        gridsArray.push({id: `main_grid_line_${power_type}`, power_type: power_type, gridLine: getMainGridLineByFlowType(geoData, power_type, projectConfig)})
    }
    return gridsArray
}

export function getMainGridLineByFlowType(geoData, power_type, projectConfig){
    const filteredRoutes = geoData.features.filter((feature)=>feature.properties.route==='grid' && feature.properties.power_type === power_type);
    // console.log(filteredRoutes)
    var updatedRoutesArr = []
    for (let route of filteredRoutes){
        const color = projectConfig.grid_color_power_type[route.properties.power_type]
        route.properties['color'] = color
        updatedRoutesArr.push(route)
    }

    return {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': updatedRoutesArr
        } 
    }
}

// both for cpoc and buildings
export function getNodeToGridLines(geoData, projectConfig){
    const nodeToGridLines =  geoData.features.filter((feature)=>feature.properties.route_type==='node_to_grid');
    const routesArr = Array.from(new Set(nodeToGridLines.map((ln)=>ln.properties.route)).values());

    var lineFeaturesArr = []
    // const gridLinesObj = getGridLinesObj(geoData, projectConfig)

    for (let route of routesArr){
        var routeLine = nodeToGridLines.find((ln)=>ln.properties.route===route)
        routeLine.properties['color'] = projectConfig.grid_color_power_type[routeLine.properties['power_type']]
        lineFeaturesArr.push(routeLine)
    }
  
    return {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': lineFeaturesArr
        } 
    }
}

export function getNodeToGridLinesForSwitchingFlows(geoData, projectConfig){
    var gridsArray = []
    for (let power_type of projectConfig.animated_flows){
        gridsArray.push({id: `node_to_grid_line_${power_type}`, power_type: power_type, gridLine: getNodeToGridLineByFlowType(geoData, power_type, projectConfig)})
    }
    return gridsArray
}

export function getNodeToGridLineByFlowType(geoData, power_type, projectConfig){
    const nodeToGridLines =  geoData.features.filter((feature)=>feature.properties.route_type==='node_to_grid' && feature.properties.power_type===power_type);
    const routesArr = Array.from(new Set(nodeToGridLines.map((ln)=>ln.properties.route)).values());

    var lineFeaturesArr = []
    // const gridLinesObj = getGridLinesObj(geoData, projectConfig)

    for (let route of routesArr){
        var routeLine = nodeToGridLines.find((ln)=>ln.properties.route===route)
        routeLine.properties['color'] = projectConfig.grid_color_power_type[routeLine.properties['power_type']]
        lineFeaturesArr.push(routeLine)
    }
  
    return {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': lineFeaturesArr
        } 
    }
}


export function getNodeToGridLinesLayer(id){

    const layer =  {
        'id': id,
        'source': id,
        'type': 'line',
        'slot': 'bottom',
        'paint': {
            'line-width': Consts.node_to_grid_static_line_width,
            'line-color': ['get', 'color'],
            'line-opacity': Consts.node_to_grid_static_line_opacity, 
            // 'line-dasharray': [2, 1],     
        }
    }
    return layer
}

export function getBuildingOutlineLayer(id, placeHolderNodes){

    const layer = {
        'id': id,
        'source': id,
        'type': 'line',
        'slot': 'top',
        'paint': {
            'line-color': Consts.default_building_outline_color,
            'line-width': placeHolderNodes.includes(id) ? Consts.placholder_building_line_width: Consts.default_building_line_width,
            // 'line-color-transition': {"duration": 300}
        }
    }
    return layer
}

export function getBuildingFillLayer(id, placeHolderNodes){
    const layer =  {
        'id': `${id}_fill`,
        'type': 'fill',
        'slot': 'top',
        'source': id, 
        'layout': {},
        'paint': {
          'fill-color': 'white', 
          'fill-opacity': placeHolderNodes.includes(id) ? 0 : 1
          }
      }
    
    return layer
}

// for all lines together to remove
export function getMainGridLinesLayer(id){
    const layer = {
        'id': id,
        'source': id,
        'type': 'line',
        'slot': 'bottom',
        'paint': {
            'line-width': Consts.main_grid_line_width,
            'line-color':  ['get', 'color'],
            'line-opacity': Consts.main_grid_line_opacity            
        }
    }  
 return layer
}

// for one line - same code - different name
export function getMainGridLineLayer(id){
    const layer = {
        'id': id,
        'source': id,
        'type': 'line',
        'slot': 'bottom',
        'paint': {
            'line-width': Consts.main_grid_line_width,
            'line-color':  ['get', 'color'],
            'line-opacity': Consts.main_grid_line_opacity            
        }
    }  
 return layer
}

export function getLayersPerPowerType(layersArr, projectConfig){
    var returnObj = {}
    // console.log(geoData)
   
    for (let flow of projectConfig.animated_flows){
        const layers = layersArr.filter((layer)=>layer.power_type===flow).map((layer)=>layer.id)
        returnObj[flow] = layers
    }
    return returnObj
}