import React from 'react'
import {Route, Routes} from "react-router-dom";

// page
import VubDemo from '../../pages/map_visualizer/DemoMap';
import TestPage from '../../pages/map_visualizer/TestPage';
import MapVub from '../../pages/map_visualizer/Map';


const MapRoutes = () => {
  return (
    <Routes>
        <Route exact path="demo" element={<VubDemo/>} />
        <Route path='/player' element={<MapVub/>}/>
    </Routes>
  )
}

export default MapRoutes;