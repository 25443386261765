import React from 'react'
import "./appBar.scss";

// icons
import { IoArrowBackOutline } from "react-icons/io5";

/*
    pageType options:
    1.page
    2.actionpage
*/



const AppBar = ({title,pageType, actionFunc, icon, text}) => {
  return (
    <div className='appBar'>
        {
            pageType === "page"? (
                <>
                <div className="titleHolderPage">
                    <span className="title">{title}</span>
                </div>
                <button className='actionBtn'
                onClick={actionFunc}
                >
                {icon}
                {text}
                </button>
                </>
            ) :(
            <>
               <div className="iconBtnHolder"
               onClick={actionFunc}
               >
                   <IoArrowBackOutline className="iconBtn" /> 
                </div>
                <div className="titleHolderAction">
                    <span className="title">{title}</span>
                </div>
            </>) 
        }
    </div>
  )
}

export default AppBar