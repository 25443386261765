export const Consts = {
    projection_height: 50, // this defines width and height of the projection of polygon in NodeInfoPanel

    default_building_outline_color: 'white',
    default_building_line_width: 4,
    selected_building_line_width: 10,
    placholder_building_line_width: 16,
    selected_placeholder_building_line_width: 22,

    main_grid_line_width: 5,
    main_grid_line_opacity: 1,

    node_to_grid_static_line_width: 1, 
    node_to_grid_static_line_opacity: 0.8,

    default_cpoc_circle_radius: 10,
    default_cpoc_circle_outer_radius: 20,
    default_cpoc_outer_circle_width: 12
}