import { createContext, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";

const API_URL = "/api/v1"
export const AuthContext = createContext();

export const AuthContextProvider = ({children}) =>{
    const [ctoken, setCtoken] = useState(Cookies.get("clientToken") || "")
    const loginUser = async(logindata) =>{
        try {
            const res = await axios.post(`${API_URL}/auth/login`, logindata);
            if(res.data.status === 200 && res.data.success === true){
                if(res.data.user.role !== "admin"){
                    await axios.post("/auth/logout");
                    return {
                        success: false,
                        access: false,
                        user: null,
                        message: "Access Denied",
                        description: "Your account is not authorized to access."
                    }
                }else{
                    // TODO: set the expiry date
                    Cookies.set("clientToken", res.data.token);
                    setCtoken(res.data.token);
                    return {
                        success: true,
                        access: true,
                        user: res.data.user,
                        message: "Login Success",
                        description: "User logged in successfully."
                    }
                }
            }
        } catch (error) {
            let errordata = {
                success: false,
                access: false,
                status: error.response.data.status,
                error: error.response.data.error,
                message: error.response.data.message
            }
            return errordata;
        }
    }

    const logoutUser = async() =>{
        try {
            const res = await axios.post(`${API_URL}/auth/logout`);
            if(res.data.status === 200 && res.data.success === true){
                Cookies.remove('clientToken')
                setCtoken("")
                return {
                    status: 200,
                    success: true,
                    user: null
                }
            }
        } catch (error) {
            
        }
    }

    return (
        <AuthContext.Provider
            value={{
                loginUser,
                logoutUser,
                ctoken
            }}
        >
            {children}
        </AuthContext.Provider>
    )

}