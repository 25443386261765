
export default function NodeInfoPanelAssets({ assetsDataArr }) {
    // for now just placeholder component
    /*
     const measurementDisplayNames = projectConfig.node_panel_measurement_display_names
    const nodeConfig = assetsConfig.filter((item)=>item.node===node)
    const nodeName = nodeConfig.find((item)=>item.parameter==='name').value
    const nodeAssets = assetsData.filter((item)=>item.node===node && item.timestep_no===(currentIndex+1))
                                 .map((item)=>{return {...item, measurement_name: measurementDisplayNames[item.measurement], 
                                    type: item.asset==='main' ? 'Main': nodeConfig.find((configItem)=>configItem.asset===item.asset).type}})
    
    return {node_name: nodeName, assets: nodeAssets}
    */

    return (
        <div className="nodeAssetsWrapper">

        </div>
    )
}