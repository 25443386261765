import React, { useState } from 'react';
import "./nodeInfoPanel.scss";
import { FaAngleLeft } from "react-icons/fa6";
import NodeInfoPanelAssets from './nodeInfoPanelAssets';
import { FaAngleRight } from "react-icons/fa6";

export default function NodeInfoPanel({ isPanelOpen, polygonCoord, handleOpenClose, panelPosition, nodeTimestempData }) {
    const [isOpen, setIsOpen] = useState(isPanelOpen)

    function toggleOpenClose() {
        setIsOpen(!isOpen)
        handleOpenClose(isOpen)
    }

    return (
        <div className={'nodeInfoPanelWrapper' + panelPosition}>
            <div className='nodeWrapper'>
                <div className='nodeName'>{nodeTimestempData.node_name}</div>
                <div className='projectionWrapper'>
                    <svg name="viewport" className='svgProjection' viewBox='0 0 100 100' width='80' height='80' xmlns="http://www.w3.org/2000/svg">
                        <polygon points={polygonCoord} fill="none" stroke="white" strokeWidth={3} />
                    </svg>
                </div>
            </div>
            {isOpen && <NodeInfoPanelAssets assetsDataArr={nodeTimestempData.assets} />}
            <div className="openCloseButton" onClick={toggleOpenClose}>
                {isOpen ? <FaAngleLeft size={'2.5vh'} /> : <FaAngleRight size={'2.5vh'} />}
            </div>
        </div>
    )

}