import React, { useState } from 'react';
import "./generalInfoPanel.scss";
import GeneralPanelClosed from './generalInfoPanelClosed';
import angle from "../../../assets/demo/angle.png";
import angleClose from "../../../assets/demo/angleclose.png";
import GeneralPanelOpen from './generalnfoPanelOpen';

export default function GeneralInfoPanel({isPanelOpen, generalInfoDataRow, handleOpenClose, panelPosition}){
    const [isOpen, setIsOpen] = useState(isPanelOpen)

    function toggleOpenClose(){
        setIsOpen(!isOpen)
        handleOpenClose(isOpen)
    }

    return (
        <div className={`generalInfoPanelWrapper${panelPosition} ${isOpen ? 'open' : 'closed'}`}>
            {isOpen ? <GeneralPanelOpen generalInfoDataRow={generalInfoDataRow}/> : <GeneralPanelClosed/>}
            <div className="openCloseButton" onClick={toggleOpenClose}>
                {isOpen ? <img src={angleClose} alt="Close panel" width="20" height="60"/> : <img src={angle} alt="Open panel" width="20" height="60"/>}
            </div>
        </div>
    );

}