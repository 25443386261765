import React, { useState, useEffect, useContext } from "react";
import {useNavigate} from "react-router-dom";
import "./login.scss";
import {notification} from "antd";

import logo from "../../assets/images/vub-logo.png";

// context
import { AuthContext } from "../../context/authContext";

// utils
import validateEmail from "../../utils/validateEmail";
import monitorInputChange from "../../utils/monitorInputChange";

const Login = () => {
  const {loginUser} = useContext(AuthContext);
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification()
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [errorParams, setErrorParams] = useState([]);

  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description
    });
  };

  const formValidation = () =>{
    let errors = [];
    let test = validateEmail(loginData.email);
      if(!test){
        errors.push({
          path: "email",
          message: "Enter valid email"
        })
      }
    if(loginData.password === ""){
      errors.push({
        path: "password",
        message: "Enter valid password"
      })
    }
    if(errors.length >0){
      setErrorParams(errors)
      return false;
    }
    return true;
  }

  const handleLogin = async() =>{
    try{
      const validate = formValidation()
      if(validate === true){
        const res = await loginUser(loginData);
        if(res.success === false && res.access === false){
          openNotificationWithIcon('error', res.error, res.message)
        }
        else if(res.success === true && res.access === true){
          navigate('/scenariomanager')
        }
      }
    }catch(error){
      console.error(error);
    }
  }


  useEffect(() =>{
    if(errorParams.length >0){
      errorParams.forEach(err =>{
        let ele = document.getElementById(err.path);
        if(ele){
          ele.style.borderColor = "red";
        }
      })
    }
  },[errorParams])



  return (
    <div className="login">
      {contextHolder}
      <div className="logoContainer">
        <img src={logo} alt="logo" />
        <div className="headerContainer">
          <span className="title">VUB</span>
          <span className="title">TableTop</span>
        </div>
      </div>
      <div className="cardTitle">
        <span>Login to your account</span>
      </div>
      <form
        className="formContainer"
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin()
        }}
      >
        <div className="inputHolder">
          <span>Email<span className="imp">*</span></span>
          <input
            id="email"
            type="email"
            placeholder="Enter email address"
            value={loginData.email}
            onChange={(e) => {
              setLoginData({
                ...loginData,
                email: e.target.value,
              });
              monitorInputChange('email')
            }}
          />
        </div>
        <div className="inputHolder">
          <span>Password<span className="imp">*</span></span>
          <input
            id="password"
            type="password"
            placeholder="Enter password"
            value={loginData.password}
            onChange={(e) => {
              setLoginData({
                ...loginData,
                password: e.target.value,
              });
              monitorInputChange('password')
            }}
          />
        </div>
        <button className="btn" type="submit">Login</button>
      </form>
    </div>

  );
};

export default Login;
