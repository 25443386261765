import { Combination } from 'js-combinatorics';
import { Consts } from './consts';
import { length } from '@turf/turf';
import {quantileSorted, mean} from 'simple-statistics';

export function parseTimeToMinutes(timeString) {
    if (!timeString) return null;
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":").map((num) => parseInt(num, 10));

    if (hours === 12) {
      hours = 0;
    }
    if (modifier === "PM") {
      hours += 12;
    }
    return hours * 60 + minutes;
  };

export function getPowerTypesForIcons(iconsArr, nodePowerType){
    var iconsFlowsArr = []
    for (let iconData of iconsArr){
        iconsFlowsArr.push({layerId: iconData.id, flows: nodePowerType.find((item)=>item.node === iconData.node).flows})
    }
    return iconsFlowsArr
}

export function getObservationsArr(assetsData){
    return Array.from(new Set(assetsData.map((item)=>item.timestep_no)).values()).sort(function(a, b) {
        return a - b;
      });
}

export function getAverageTimestampForTimestep(assetsData, timestepNo) {
    const dataForTimestep = assetsData.filter(item => item.timestep_no === timestepNo+1);

    if (dataForTimestep.length === 0) {
        return null;
    }

    const totalTimestamps = dataForTimestep.reduce((acc, item) => {
        const startTimestamp = item.start_timestamp;
        const endTimestamp = item.end_timestamp;
        return acc + (startTimestamp + endTimestamp) / 2;
    }, 0);

    const averageTimestamp = totalTimestamps / dataForTimestep.length;

    const date = new Date(averageTimestamp * 1000);
    const formattedDate = date.toISOString().slice(0,10)
    const formattedTime = date.toISOString().slice(11, 19);
    return {formattedTime, formattedDate};
}


export function getNodeColorsArrToLoop(timestepsArr, assetsData, projectConfig){
    const colorAssetsData = assetsData.filter((data)=>data.measurement==='color')
    var nodeColorArrayToLoop = []
    for (let timestep of timestepsArr){
        var timestepArr = []
        const timestepData = colorAssetsData.filter((data)=>data.timestep_no===timestep)    
        for (let row of timestepData){
            timestepArr.push({node: row.node, node_color:  projectConfig.node_color_map[row.value]})
        }
        nodeColorArrayToLoop.push(timestepArr)
    }
    return nodeColorArrayToLoop
}

export function getNodeAssetDataForTimestep(node, currentIndex, assetsData, assetsConfig, projectConfig){
    const measurementDisplayNames = projectConfig.node_panel_measurement_display_names ? projectConfig.node_panel_measurement_display_names : 
                                   {"color": "CO2Emissions", "el_power": "ENERGY", "th_power": "ENERGY", "gas_power": "ENERGY", "soc":"BATTERY CHARGE"}
    const nodeConfig = assetsConfig.filter((item)=>item.node===node)
    const nodeName = nodeConfig.find((item)=>item.parameter==='name').value
    const nodeAssets = assetsData.filter((item)=>item.node===node && item.timestep_no===(currentIndex+1))
                                 .map((item)=>{return {...item, measurement_name: measurementDisplayNames[item.measurement], 
                                                       type: item.asset==='main' ? 'Main': nodeConfig.find((configItem)=>configItem.asset===item.asset).type}})
    
    return {node_name: nodeName, assets: nodeAssets}
}

export function getPlaceholderNodes(assetsConfig){
    const placeHolderNodes = assetsConfig.filter((item)=>item.parameter==='node_type' && !['building', null].includes(item.value)).map((item)=>item.node)
    return placeHolderNodes
}

export function getPowerTypesForNodes(geoData){
    const nodeToGridLines =  geoData.features.filter((feature)=>feature.properties.route_type==='node_to_grid');
    const allNodes = Array.from(new Set(nodeToGridLines.map((feature)=>feature.properties.node)).values())
    var nodesFlowsArr = []
    for (let node of allNodes){
        nodesFlowsArr.push({node: node, flows: nodeToGridLines.filter((feature)=>feature.properties.node===node).map((feature)=>feature.properties.power_type)})
    }
    return nodesFlowsArr
}


export function getAnimationRoutesForBuildings(geoData, assetsData, mapRef, projectConfig, timestepsArr, animation){
    const nodeToGridLines =  geoData.features.filter((feature)=>feature.properties.route_type==='node_to_grid');
    const routesArr = Array.from(new Set(nodeToGridLines.map((ln)=>ln.properties.route)).values());

    var animatedRoutesArr = []

    for (let route of routesArr){
        const routeLine = nodeToGridLines.find((ln)=>ln.properties.route===route);
       
        const routeNode = routeLine.properties.node
        const routePowerType = routeLine.properties.power_type
        const node_asset_energy_tag =routeNode.includes('cpoc')? `site.${routeNode}.${routePowerType}`: `${routeNode}.main.${routePowerType}`
   

        if (assetsData.filter((data) => data.tag === node_asset_energy_tag).length===0){
            continue;
        }

        // const energyArr = assetsData.filter((data) => data.tag === node_asset_energy_tag).map((data) => data.value);
        // const energySum = energyArr.reduce((partialSum, a) => partialSum + a, 0)

        const lineStartingPoint = routeLine.geometry.coordinates[0]
        var lineLastPoint = routeLine.geometry.coordinates[routeLine.geometry.coordinates.length-1]

        // const minValue = Math.min(...assetsData.filter((data) => data.tag === node_asset_energy_tag).map((data)=>data.value))
        // const maxValue = Math.max(...assetsData.filter((data) => data.tag === node_asset_energy_tag).map((data)=>data.value))
        const absValuesArr = assetsData.filter((data) => data.tag === node_asset_energy_tag).map((data)=>Math.abs(data.value))
        const minValue = Math.min(...absValuesArr)
        const maxValue = Math.max(...absValuesArr)

        const minValueNonZero = Math.min(...absValuesArr.filter((value)=>value!==0)) //becomes Infinity if the array is empty, ie all zeros
        const maxValueNonZero = Math.max(...absValuesArr.filter((value)=>value!==0)) // becomes -Infinity if the array is empty, ie all zeros

        // const pointFrom = energySum >= 0 ? mapRef.project(lineStartingPoint) : mapRef.project(lineLastPoint)
        // const pointTo = energySum >= 0 ? mapRef.project(lineLastPoint) : mapRef.project(lineStartingPoint)

        const dist = length(routeLine)

        const routeObj = {id: route, origin: mapRef.project(lineStartingPoint), destination: mapRef.project(lineLastPoint), color: projectConfig.circle_color_power_type[routePowerType], 
                            tag: node_asset_energy_tag, node: routeNode, min: minValue, max: maxValue, distance: dist, 
                            minDuration: maxValueNonZero !==-Infinity ? dist/maxValueNonZero : undefined,
                            maxDuration: minValueNonZero !== Infinity ? dist/minValueNonZero : undefined,
                            route_power_type: routePowerType // for zeros - we are just taking the longest duration - anyways the circle radius will be zero
                        }                                           

        animatedRoutesArr.push(routeObj)
    }

    var animatedPointsObjectsArr = []

    const totalMinNonZero = Math.min(...animatedRoutesArr.filter((item)=>item.min!==0).map((item)=>Math.abs(item.min))) // min energy value
    const totalMax = Math.max(...animatedRoutesArr.map((item)=>item.max)) // max energy value
 

    // const actualMaxDuration = Math.max(...animatedRoutesArr.map((item)=>item.min===0 ? 0 : item.distance / Math.abs(item.min)).filter((item)=>item!==0)) // the slowest
    // const actualMinDuration = Math.min(...animatedRoutesArr.map((item)=>item.min===0 ? 0 : item.distance / Math.abs(item.max)).filter((item)=>item!==0)) // the fastest

    const actualMaxDuration = Math.max(...animatedRoutesArr.filter((item)=>item.maxDuration).map((item)=>item.maxDuration)) // the slowest
    const actualMinDuration = Math.min(...animatedRoutesArr.filter((item)=>item.minDuration).map((item)=>item.minDuration)) // the fastest
    // console.log(actualMaxDuration)
    // console.log(actualMinDuration)

    // const maxDuration = projectConfig.timestamp_duration // or set something else - separate value in config file
    // const minDuration = projectConfig.min_animation_duration // add this value to config file

    const maxDuration = projectConfig.max_animation_duration  // or set something else - separate value in config file
    const minDuration = projectConfig.min_animation_duration//to be replaced with projectConfig.min_animation_duration

    const durationRange = maxDuration - minDuration
    const actualDurationRange = actualMaxDuration - actualMinDuration
    
    const slope = durationRange/actualDurationRange // basically dy/dx
    const yIntercept = minDuration - slope * actualMinDuration; 

    const maxAnimatedObjSize = projectConfig.max_circle_line_size //we dont need min to reflect zero values - add to config

    const sizeRange = maxAnimatedObjSize - 3 // 3 is min object size
    const sizeSlope = sizeRange/(totalMax - totalMinNonZero) // basically dy/dx
    const yInterceptSize = 3 - sizeSlope * totalMinNonZero; 

    for (let timestep of timestepsArr){
         var arrCopy = [] 
         const timestepAssetsData = assetsData.filter((data)=>data.timestep_no===timestep)
         for (let routeData of animatedRoutesArr){
            var newRouteData = JSON.parse(JSON.stringify(routeData))
            const energyValue = timestepAssetsData.find((data)=>data.tag === routeData.tag).value

            const animatedObjSize = energyValue===0 ? 0 : (sizeSlope * Math.abs(energyValue))+yInterceptSize
            newRouteData['size'] = animatedObjSize

            //option 1 - distance adjusted
            const newDuration = energyValue===0 ? maxDuration : (slope * routeData.distance/Math.abs(energyValue)) + yIntercept; // for yn of linear function y=ax+b
            newRouteData['duration'] = newDuration

            newRouteData['from'] = energyValue > 0 ? routeData.origin : routeData.destination
            newRouteData['to'] = energyValue > 0 ? routeData.destination : routeData.origin 
            newRouteData['timestep_no'] = timestep

            arrCopy.push(newRouteData)
         }
     
         animatedPointsObjectsArr.push(arrCopy)
    }
    return {points_object : animatedPointsObjectsArr, routes: animatedRoutesArr}
}

export function getPolygonsProjectionsObj(mapRef, data){
    const allNodes = data.features.filter((feature)=>(feature.geometry.type==='Polygon')).map((item)=>item.properties.node)
    var polygonsProjectionObj = {}
    for (let building of allNodes){
        polygonsProjectionObj[building] = getPolygonSvgCoord(mapRef, data, building)
    }
    return polygonsProjectionObj
  }

export function getPolygonSvgCoord(mapRef, data, node){
    // const maxHeight = 50 // should be somewhere in consts
    const diagonal = Math.sqrt(2*(Consts.projection_height**2))
    const building = data.features.find((feature)=>(feature.geometry.type==='Polygon')&& (feature.properties.node===node)); 
    const polygonPointsInPx = building.geometry.coordinates[0].map((coord)=>mapRef.project(coord)) // array of Mapbox Point objects
    const combinationsOfPoints = new Combination(polygonPointsInPx, 2).toArray();

    const pointsDistancesArr = combinationsOfPoints.map((combination)=>        
         Math.sqrt((combination[0].x - combination[1].x)**2 + (combination[0].y - combination[1].y)**2)
    )
    const maxDistance = Math.max(...pointsDistancesArr)
    const coordConversionRatio = diagonal/maxDistance
    var pointsForSvg = polygonPointsInPx.map((vertex)=>[vertex.x*coordConversionRatio, vertex.y*coordConversionRatio])
    const minX = Math.min(...pointsForSvg.map((vertex)=>vertex[0]))
    const minY = Math.min(...pointsForSvg.map((vertex)=>vertex[1]))
    pointsForSvg = pointsForSvg.map((vertex)=>[vertex[0]-minX, vertex[1]-minY])
    const polygonVertexString = pointsForSvg.map((vertex)=>vertex[0].toString()+','+vertex[1].toString()).join(' ')
    return polygonVertexString
  }

  export function getPercentiles(assetsData){
    // const data = assetsData.filter((datum)=>datum.value!==0 && ["th_power", "el_power", "gas_power"].includes(datum.measurement) &&
    //                                     !datum.node.includes('cpoc')).map((datum)=>Math.abs(datum.value)).sort((a, b) => a - b)

    // const data = assetsData.filter((datum)=>datum.value!==0 && ["th_power", "el_power", "gas_power"].includes(datum.measurement)).map((datum)=>Math.abs(datum.value)).sort((a, b) => a - b)
    const data = assetsData.filter((datum)=>datum.value!==0 && ["th_power", "el_power", "gas_power"].includes(datum.measurement) &&
                                    datum.asset==='main').map((datum)=>Math.abs(datum.value)).sort((a, b) => a - b)
    const perLow = quantileSorted(data, 0.05)
    const perHigh =  quantileSorted(data, 0.95)
    return {'low': perLow, 'high': perHigh}
  }

  export function getDurationPercentiles(durationArr){
    const data = durationArr.sort((a, b) => a - b)
    const perLow = quantileSorted(data, 0.05)
    const perHigh =  quantileSorted(data, 0.95)
    console.log({'low': perLow, 'high': perHigh})
    return {'low': perLow, 'high': perHigh}
  }

  export function getMean(assetsData){
    const data = assetsData.filter((datum)=>datum.value!==0).map((datum)=>Math.abs(datum.value)).sort((a, b) => a - b)
    const m = mean(data)
    return m
  }

  // do not remove - function to play around with sizes and velocities 
  export function getAnimationRoutesForBuildingsAlternative(geoData, assetsData, mapRef, projectConfig, timestepsArr, animation){
    const nodeToGridLines =  geoData.features.filter((feature)=>feature.properties.route_type==='node_to_grid');
    const routesArr = Array.from(new Set(nodeToGridLines.map((ln)=>ln.properties.route)).values());

    var animatedRoutesArr = []

    for (let route of routesArr){
        const routeLine = nodeToGridLines.find((ln)=>ln.properties.route===route);
       
        const routeNode = routeLine.properties.node
        const routePowerType = routeLine.properties.power_type
        const node_asset_energy_tag =routeNode.includes('cpoc')? `site.${routeNode}.${routePowerType}`: `${routeNode}.main.${routePowerType}`
   

        if (assetsData.filter((data) => data.tag === node_asset_energy_tag).length===0){
            continue;
        }

        const energyArr = assetsData.filter((data) => data.tag === node_asset_energy_tag).map((data) => data.value);
        const energySum = energyArr.reduce((partialSum, a) => partialSum + a, 0)

        const lineStartingPoint = routeLine.geometry.coordinates[0]
        var lineLastPoint = routeLine.geometry.coordinates[routeLine.geometry.coordinates.length-1]

        // const minValue = Math.min(...assetsData.filter((data) => data.tag === node_asset_energy_tag).map((data)=>data.value))
        // const maxValue = Math.max(...assetsData.filter((data) => data.tag === node_asset_energy_tag).map((data)=>data.value))
        const absValuesArr = assetsData.filter((data) => data.tag === node_asset_energy_tag).map((data)=>Math.abs(data.value))
        const minValue = Math.min(...absValuesArr)
        const maxValue = Math.max(...absValuesArr)

        const minValueNonZero = Math.min(...absValuesArr.filter((value)=>value!==0)) //becomes Infinity if the array is empty, ie all zeros
        const maxValueNonZero = Math.max(...absValuesArr.filter((value)=>value!==0)) // becomes -Infinity if the array is empty, ie all zeros

        const pointFrom = energySum >= 0 ? mapRef.project(lineStartingPoint) : mapRef.project(lineLastPoint)
        const pointTo = energySum >= 0 ? mapRef.project(lineLastPoint) : mapRef.project(lineStartingPoint)

        const dist = length(routeLine)

        const routeObj = {id: route, from: pointFrom, to: pointTo, color: projectConfig.circle_color_power_type[routePowerType], 
                            tag: node_asset_energy_tag, node: routeNode, min: minValue, max: maxValue, distance: dist, 
                            minDuration: maxValueNonZero !==-Infinity ? dist/maxValueNonZero : undefined,
                            maxDuration: minValueNonZero !== Infinity ? dist/minValueNonZero : undefined // for zeros - we are just taking the longest duration - anyways the circle radius will be zero
                        }                                           

        animatedRoutesArr.push(routeObj)
    }

    var animatedPointsObj = {}

    const totalMinNonZero = Math.min(...animatedRoutesArr.filter((item)=>item.min!==0).map((item)=>Math.abs(item.min))) // min energy value
    const totalMax = Math.max(...animatedRoutesArr.map((item)=>item.max)) // max energy value
 

    // const actualMaxDuration = Math.max(...animatedRoutesArr.map((item)=>item.min===0 ? 0 : item.distance / Math.abs(item.min)).filter((item)=>item!==0)) // the slowest
    // const actualMinDuration = Math.min(...animatedRoutesArr.map((item)=>item.min===0 ? 0 : item.distance / Math.abs(item.max)).filter((item)=>item!==0)) // the fastest
    
    const actualMaxDuration = Math.max(...animatedRoutesArr.filter((item)=>item.maxDuration).map((item)=>item.maxDuration)) // the slowest
    const actualMinDuration = Math.min(...animatedRoutesArr.filter((item)=>item.minDuration).map((item)=>item.minDuration)) // the fastest
    // console.log(actualMaxDuration)
    // console.log(actualMinDuration)

    // const maxDuration = projectConfig.timestamp_duration // or set something else - separate value in config file
    // const minDuration = projectConfig.min_animation_duration // add this value to config file

    const maxDuration = projectConfig.timestamp_duration // or set something else - separate value in config file
    const minDuration = 400 //to be replaced with projectConfig.min_animation_duration

    const durationRange = maxDuration - minDuration
    const actualDurationRange = actualMaxDuration - actualMinDuration
    
    const slope = durationRange/actualDurationRange // basically dy/dx
    const yIntercept = minDuration - slope * actualMinDuration; 
    // console.log(slope)
    // console.log(yIntercept)

    
    ////////
    // const maxEnergy = totalMax
    // const minEnergy = totalMinNonZero
    // const energyRange = minEnergy - maxEnergy
    // const slopeNew = durationRange / energyRange
    // const yInterceptNew = minDuration - slopeNew * maxEnergy
    // console.log(slopeNew)
    // console.log(yInterceptNew)

    ///////
 
    const maxAnimatedObjSize = projectConfig.max_circle_line_size //we dont need min to reflect zero values - add to config

    const sizeRange = maxAnimatedObjSize - 3 // 3 is min object size
    const sizeSlope = sizeRange/(totalMax - totalMinNonZero) // basically dy/dx
    const yInterceptSize = 3 - sizeSlope * totalMinNonZero; 

    for (let timestep of timestepsArr){
         var arrCopy = [] 
         const timestepAssetsData = assetsData.filter((data)=>data.timestep_no===timestep)
         for (let routeData of animatedRoutesArr){
            var newRouteData = JSON.parse(JSON.stringify(routeData))
            const energyValue = timestepAssetsData.find((data)=>data.tag === routeData.tag).value

            const animatedObjSize = energyValue===0 ? 0 : (sizeSlope * Math.abs(energyValue))+yInterceptSize
            newRouteData['size'] = animatedObjSize

            //option 1 - distance adjusted
            const newDuration = energyValue===0 ? maxDuration : (slope * routeData.distance/Math.abs(energyValue)) + yIntercept; // for yn of linear function y=ax+b
            newRouteData['duration'] = newDuration

            //option 2 = jsut enregy = doesnt really work for now
            // const newDuration = energyValue===0 ? maxDuration : (slopeNew * Math.abs(energyValue)) + yInterceptNew; // for yn of linear function y=ax+b
            // newRouteData['duration'] = newDuration

            arrCopy.push(newRouteData)
         }
     
         animatedPointsObj[timestep] = arrCopy
    }
    return {points_object : animatedPointsObj, routes: animatedRoutesArr}
}