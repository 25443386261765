import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { getMainGridLine, getNodeToGridLines, getAllNodesForLayers, getPolygonsProjectionsObj, getAssetsPowerObj, getAnimationPointsRoutes, getAssetsDataForGeneralInfoPanel } from '../../utils/demo/utiltiesDemoSpring';
import PopupComponent from '../Popup/popup';
import { createRoot } from 'react-dom/client';
import "mapbox-gl/dist/mapbox-gl.css";
import { CirclesComponentMultisprings } from '../Animations/Demo/circles';
import { LinesComponentMultisprings } from '../Animations/Demo/chargingLines';
import { LinesComponentMultispringsTwoParts } from '../Animations/Demo/snakeLines';
import "./map.scss";
import GeneralInfoPanel from '../Panels/Demo/generalnfoPanel';

import nextIcon from '../../assets/demo/next.png'
import playIcon from '../../assets/demo/play.png'
import stopIcon from '../../assets/demo/stop.png'
import NodeInfoPanel from '../Panels/Demo/nodeInfoPanel';


export function MapDemo({assetsData, routesData, projectConfig, screenHeight, screenWidth}){

    const [center_lng, setLng] = useState(projectConfig.centerLng);
    const [center_lat, setLat] = useState(projectConfig.centerLat);
    const [zoom, setZoom] = useState(projectConfig.zoom);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const popUpRef = useRef(new mapboxgl.Popup({ offset: 15, maxWidth: '800px', closeOnClick: false }))
    const inter = projectConfig.timestamp_duration
    mapboxgl.accessToken = projectConfig.map_token
    const [animatedPointsToLoop, setAnimatedPointsToLoop] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedAnimation, setSelectedAnimation] = useState('charging lines');
 
    // for general info panel
    const [isGeneralPanelOpen, setIsGeneralPanelOpen] = useState(false)
    const [generalInfoPanelData, setGeneralInfoPanelData] = useState([])

    const [isMapReady, setIsMapReady] = useState(false)
    
    //node selection
    // const [  ,setPreviousNodeIndex] = useState()
    const [currentNodeIndex, setCurrentNodeIndex] = useState(0)
    const [allNodes, setAllNodes] = useState([])

    //node info panel
    const [isNodePanelOpen, setIsNodePanelOpen] = useState(false)
    
    // Will use this later
    // eslint-disable-next-line
    const [nodeInfoPanelData, setnodeInfoPanelData] = useState([]) // to iterate after, this will be used later not deleting it.
    
    const [polygonsObj, setPolygonsObj] = useState({})

    const [isBuildingColored, setIsBuildingColored] = useState(false)

    useEffect(() => {  
        if (map.current) return;    

        var buildings = getAllNodesForLayers(routesData)
        const allBuilgingIds = buildings.map((item)=>item.id)
        setAllNodes(allBuilgingIds)
        // console.log(buildings)

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: projectConfig.map_style,
            center: [center_lng, center_lat],
            zoom: zoom,
            bearing: projectConfig.mapBearing
        });

        const generalDataArr = getAssetsDataForGeneralInfoPanel(assetsData)
        setGeneralInfoPanelData(generalDataArr)

        var animatedPointsSets = getAnimationPointsRoutes(routesData, assetsData, map.current);
        setAnimatedPointsToLoop(Object.values(animatedPointsSets['points_object']))
        // setAnimatedPointsToLoop(animatedPointsSets['points_object'])

        const polygonsProjections = getPolygonsProjectionsObj(map.current, routesData)
        // setPolygonsProjectionsObj(polygonsProjections)
        setPolygonsObj(polygonsProjections)

        map.current.on('move', () => {
            setIsMapReady(false)

            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));

            var animatedPointsSets = getAnimationPointsRoutes(routesData, assetsData, map.current);
            setAnimatedPointsToLoop(Object.values(animatedPointsSets['points_object']))
        });

        map.current.on('load', () => {
            map.current.addSource('main_grid_line', getMainGridLine(routesData));
            map.current.addLayer({
                    'id': 'main_grid_line',
                    'source': 'main_grid_line',
                    'type': 'line',
                    'slot': 'bottom',
                    'paint': {
                        'line-width': 2,
                        'line-color': '#e6d25f',
                        'line-opacity': 1,    
                        'line-dasharray': [2, 1],                
                    }
                });
            // add nodes to grid lines
            map.current.addSource('node_to_grid_lines', getNodeToGridLines(routesData));
            map.current.addLayer(
                    {
                        'id': 'node_to_grid_lines',
                        'source': 'node_to_grid_lines',
                        'type': 'line',
                        'slot': 'bottom',
                        'paint': {
                            'line-width': 2,
                            'line-color': ['get', 'color'],
                            'line-opacity': 1,    
                            'line-dasharray': [2, 1],                
                        }
                    }
            )

            for (let building of buildings) {
                //   console.log(pointData.point);
                  map.current.addSource(building.id, { 'type': 'geojson', 'data': building.polygon });
                  map.current.addLayer({
                    'id': building.id,
                    'source': building.id,
                    'type': 'line',
                    'slot': 'top',
                    'paint': {
                        'line-color':'black',
                        'line-width': 4
                    }
                  });

                  map.current.addLayer({
                    'id': `${building.id}_fill`,
                    'type': 'fill',
                    'slot': 'top',
                    'source': building.id, 
                    'layout': {},
                    'paint': {
                      'fill-color': 'red', 
                      'fill-opacity': 0
                      }
                  });
            }

            // setLoaded(true);
            setIsMapReady(true);

            // clicking the map is not working because we have svg on top of the map
            map.current.on('click', (e)=>{
                console.log(e);
                var features;
                if (projectConfig.map_style==="mapbox://styles/natakr/clr0m7n11013v01r594gbbwc5"){
                  features = map.current.queryRenderedFeatures(e.point, {layers: ["vub-static-cvnnhy"]});                  
                } else {
                  features = map.current.queryRenderedFeatures(e.point, {layers: ["Building"]})
                } 
                if (features.length > 0){ // need to make it work for aprticular clicked node - now i only work with one node
                    const feature = features[0];
                    const nodeAssetsData = assetsData.filter((datum)=>datum.node===feature.properties.node)
                    const assetsPowerObj = getAssetsPowerObj(nodeAssetsData, currentIndex)
                    // const pointSteps = Object.values(animatedPointsSets)[0][0].route.length
                    const popupNode = document.createElement('div');
                    const root = createRoot(popupNode) 
                    root.render(<PopupComponent node={feature.properties.node} 
                        node_name={feature.properties.node_name} 
                        nodeAssetData={nodeAssetsData}
                        assetsPowerData={assetsPowerObj}
                        currentStep={currentIndex}
                        interval={inter}
                        polygonCoord = {polygonsProjections[feature.properties.node]}
                        />)
                    popUpRef.current
                        // .setLngLat(e.lngLat)
                        .setLngLat({lat: 50.8798, lng: 4.29542})
                        // .setLngLat({lat: 50.8800, lng: 4.29389})
                        .setDOMContent(popupNode)
                        .addTo(map.current)
                }
            })

        });


    },  [routesData, projectConfig.map_style, projectConfig.mapBearing, center_lng, center_lat, zoom, assetsData, currentIndex, inter])

    useEffect(() => {
        const handleKeyPress = (e) => {
          e.preventDefault()

          if (projectConfig.node_switch_keys.includes(e.which)) {
            const prevIndex = currentNodeIndex;
            const nextIndex = currentNodeIndex === allNodes.length - 1 ? 0 : currentNodeIndex + 1;

            map.current.setPaintProperty(allNodes[prevIndex], 'line-width', 4);
            map.current.setPaintProperty(allNodes[nextIndex], 'line-width', 11);
            // setPreviousNodeIndex(prevIndex);
            setCurrentNodeIndex(nextIndex);
          }
        };

        if (map.current !== null) {
          map.current.getCanvas().focus();
          map.current.getCanvas().parentNode.classList.remove('mapboxgl-interactive');
          map.current.getCanvas().addEventListener('keypress', handleKeyPress);
        }

        return () => {
          if (map.current !== null) {
            map.current.getCanvas().removeEventListener('keypress', handleKeyPress);
          }
        };
      }, [currentNodeIndex, allNodes, projectConfig]);

    useEffect(() => {
      // Set a timeout to update the text property after 3 seconds
      const timeout = setTimeout(() => {
        // Increment the index to move to the next property
        let animatedPoints = animatedPointsToLoop[currentIndex];
        for (let pointData of animatedPoints){
          map.current.setPaintProperty(pointData.node, 'line-color', pointData.co_emissions_color)
          map.current.setPaintProperty(`${pointData.node}_fill`, 'fill-color', pointData.co_emissions_color)
        }
        if (isMapReady){
            setCurrentIndex(prevIndex =>
            prevIndex === animatedPointsToLoop.length - 1 ? 0 : prevIndex + 1
            );
        }
      }, inter);
  
      // Clear the timeout when the component unmounts or when properties are updated
      return () => clearTimeout(timeout);
    }, [currentIndex, animatedPointsToLoop.length, animatedPointsToLoop, inter, isMapReady]);

    function handleNodeSwitch(){
        const prevIndex = currentNodeIndex
        const nextIndex = currentNodeIndex === allNodes.length - 1 ? 0 : currentNodeIndex + 1

        map.current.setPaintProperty(allNodes[prevIndex], 'line-width', 4)
        map.current.setPaintProperty(allNodes[nextIndex], 'line-width', 11)
        // setPreviousNodeIndex(prevIndex)
        setCurrentNodeIndex(nextIndex)        
    }

    function handleAnimationStart(){
        setIsMapReady(prevValue =>
            !prevValue
          );
    }

    const handleKeyDownForControls = (event) => {
        // Prevent default action for keyboard events
        event.preventDefault();
      };

    function toggleIsNodePanelOpen(value){
        setIsNodePanelOpen(!value);
    }


    function toggleIsGeneralPanelOpen(value){
        setIsGeneralPanelOpen(!value);
    }

    function toggleIsBuildingColored(){
      let animatedPoints = animatedPointsToLoop[currentIndex];
      for (let pointData of animatedPoints){
        if (isBuildingColored){
          map.current.setPaintProperty(`${pointData.node}_fill`, 'fill-opacity', 0)
        } else {
          map.current.setPaintProperty(`${pointData.node}_fill`, 'fill-opacity', 1)
        }
      }
      setIsBuildingColored(prevValue =>
        !prevValue
      );
    }

    return (
        <div>
            <div className="sidebar">
                Longitude: {center_lng} | Latitude: {center_lat} | Zoom: {zoom}
            </div>

            <div className='dropDownContainer'>
                <select value={selectedAnimation} onChange={(e) => setSelectedAnimation(e.target.value)}>
                    <option value="charging lines">charging lines</option>
                    <option value="snake lines">snake lines</option>
                    <option value="circles">circles</option>
                </select>
            </div>

            <div className='toggleFillBtnWrapper'>
              <button onClick={toggleIsBuildingColored}>{isBuildingColored? 'Unfill': 'Fill'}</button>
            </div>

            <div className='controlsWrapper'>
              <img className='stopStartBtn' src={isMapReady? stopIcon : playIcon} alt="stopStartBtn" width="20" height="20"  
                    onKeyDown={handleKeyDownForControls} onClick={handleAnimationStart}/>
              <img className='nextBtn' src={nextIcon} alt="nextIcon" width="20" height="20" onClick={handleNodeSwitch} onKeyDown={handleKeyDownForControls} />
            </div>

            <div ref={mapContainer} className="map-container" style={{height: screenHeight, width: screenWidth}}>              
            {map.current!==null && isMapReady && (selectedAnimation === 'charging lines' ? <LinesComponentMultisprings dataStep={currentIndex} circlesDataArr={animatedPointsToLoop[currentIndex]} screenHeight={screenHeight} screenWidth={screenWidth}/> :
              (selectedAnimation === 'snake lines'? <LinesComponentMultispringsTwoParts dataStep={currentIndex} circlesDataArr={animatedPointsToLoop[currentIndex]} screenHeight={screenHeight} screenWidth={screenWidth}/>:
              <CirclesComponentMultisprings dataStep={currentIndex} circlesDataArr={animatedPointsToLoop[currentIndex]} screenHeight={screenHeight} screenWidth={screenWidth}/>)              
              )}
            
            </div> 

            {map.current!==null && <NodeInfoPanel isPanelOpen={isNodePanelOpen} polygonCoord={polygonsObj[allNodes[currentNodeIndex]]} handleOpenClose={toggleIsNodePanelOpen} 
                                                 panelPosition={projectConfig.node_info_panel_position} node={allNodes[currentNodeIndex]}/>}          

            <GeneralInfoPanel isPanelOpen={isGeneralPanelOpen} generalInfoDataRow={generalInfoPanelData[currentIndex]} handleOpenClose={toggleIsGeneralPanelOpen} 
                              panelPosition={projectConfig.general_info_panel_position}/>
        </div>
    );
}