import { useContext } from "react";
import { 
  BrowserRouter, 
  Routes, 
  Route,
  Outlet, 
  Navigate
 } from "react-router-dom";
import "./style.scss";
import "./app.scss";

// context
import { AuthContext } from "./context/authContext";

// pages
import Home from "./pages/home/Home";
import ScenarioManager from "./pages/scenarioManager/ScenarioManager";
import MapVisualizer from "./pages/map_visualizer/MapVisualizer";

// components
import Navbar from "./components/Navbar/Navbar";

// routes
import ScenarioManagerRoutes from "./routes/scenarioManager/ScenarioManagerRoutes";
import MapRoutes from "./routes/map/MapRoutes";

function App() {

const {ctoken} = useContext(AuthContext)

const layoutStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100dvh",
  position:  "relative",
  padding: "60px 20px 0px 20px",
  boxSizing: "border-box",
  overflow: "auto"
}

 

 const Layout = () =>{
  if(ctoken === ""){
    return(
      <Navigate to="/" />
    ) 
  }else{
    return (
      <div style={layoutStyle}>
        <Navbar />
        <Outlet />
      </div>
    ) 
  }
 }

 const HomeLayout = () =>{
  if(ctoken === ""){
    return <Home />
  }else{
    return(<Navigate to="/scenariomanager" />)
  }
 }

  return (
    <div className={"theme-dark"}>
      <BrowserRouter>
        <div className="appContainer">
          <Routes>
            <Route path="/" element={<HomeLayout />} />

            <Route path="/scenariomanager"
              element={<Layout />}
            >
              <Route index element={<ScenarioManager />} />
              <Route
                path="/scenariomanager/*"
                element={<ScenarioManagerRoutes />}
              />
            </Route>

            <Route path="/map">
              <Route index element={ctoken === "" ? <Home /> : <MapVisualizer />} />
              <Route
                path="/map/*"
                element={<MapRoutes />}
              />
            </Route>

            {/* Handle unknown routes or show a 404 page */}
            {/* <Route element={<NotFoundComponent/>} /> */}
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
