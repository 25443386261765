import React from "react";
import "../Map/gridSelectorNew.scss";
import { LuSun, LuMoon } from "react-icons/lu";

const ClockAndLocation = ({ name, time, date, sunrise, sunset }) => {
  const [hours, minutes] = time.split(":").map((num) => parseInt(num, 10));
  const totalTimeInMinutes = hours * 60 + minutes;

  const dateAsDate = new Date(date);
  const formattedDate = new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', weekday: 'short' }).format(dateAsDate);


  const isDaytime =
    totalTimeInMinutes >= sunrise &&
    totalTimeInMinutes < sunset;

  return (
    <div className="gridSelectionWrapper">
      <div className="iconAndSeparator">
        {isDaytime ? (
          <LuSun size={'2.5vh'} color="#B6EFEA" />
        ) : (
          <LuMoon size={'2.5vh'} color="#B6EFEA" />
        )}
        <div className="verticalLine"></div>
      </div>
      <div className="rowItems">
        <div className="time">{time}</div>
        <div className="date">{formattedDate}</div>
        <div style={{ paddingLeft: '2vw' }}></div>
        <div className="name">{name}</div>
      </div>
      <div className="gridOptionsContainer"></div>
    </div>
  );
};

export default ClockAndLocation;
