import React from "react";
import Co2Svg from "../SvgIcons/co2";
import EnergyBalanceSvg from "../SvgIcons/energyBalance";
import MonetarySvg from "../SvgIcons/monetary";
import "./generalInfoPanel.scss";


const MeasurementValue = ({ icon, label, value, unit }) => (
  <div className="measurement">
    <div className="measurementIconWrapper">
      {icon}
    </div>
    <div className="measurementLabel"><b>{label}</b></div>
    <div className="measurementValue"><b>{typeof value === 'number' ? value.toFixed(2) : value} {unit}</b></div>
  </div>
);

const orderedTypes = [
  { key: "ind_monetary", IconComponent: MonetarySvg },
  { key: "ind_co2eq", IconComponent: Co2Svg },
  { key: "ind_energybalance", IconComponent: EnergyBalanceSvg },
];


export default function MeasurementCard({ measurementDataObj, projectConfig }) {
  const iconColor = projectConfig.general_panel_indicators_colors[measurementDataObj.node] ?
    projectConfig.general_panel_indicators_colors[measurementDataObj.node] : "#878787"

  const measurementUnits = projectConfig.general_panel_measurement_units
  const labelDisplayNames = projectConfig.general_panel_indicators_display_names

  const renderMeasurementValues = () => orderedTypes.map(({ key, IconComponent }) => {
    const measurement = measurementDataObj.measurements.find(m => m.measurement === key);
    if (!measurement) return null;
    return (
      <MeasurementValue
        key={key}
        icon={<IconComponent color={iconColor} />}
        label={labelDisplayNames[key]}
        value={measurement.value}
        unit={measurementUnits[measurement.measurement]}
      />
    );
  }).filter(Boolean);

  return (
    <div className="measurementCardWrapper">
      <div className="measurementCardTitle">{measurementDataObj.display_name || 'SITE'}</div>
      <div className={`valuesWrapper ${measurementDataObj.node || ''}`}>
        {renderMeasurementValues()}
      </div>
    </div>
  );
}
