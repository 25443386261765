import React, { useEffect, useState } from "react";
import "./scenarioForm.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// icon
import { BsFileEarmarkPlus } from "react-icons/bs";
import { LuFileJson } from "react-icons/lu";
import { BsFiletypeCsv } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";

const API_URL = "/api/v1";

const ScenarioForm = ({ inpdata, edit }) => {
  const navigate = useNavigate();
  const [inputdata, setInputdata] = useState(inpdata);
  const [inputFiles, setInputFiles] = useState({
    project_config: {
      filename: null,
      file: null,
    },
    config: {
      filename: null,
      file: null,
    },
    data: {
      filename: null,
      file: null,
    },
    geojson: {
      filename: null,
      file: null,
    },
  });
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [errorParams, setErrorParams] = useState([]);

  const monitorInputChange = (ele) => {
    let elem = document.getElementById(ele);
    if (elem) {
      elem.style.border = "none";
    }
  };

  const handleFileLoad = (e, filetype) => {
    let filename = ""
    const file = e.target.files[0];

    if(file){
      filename = file.name
      if (filetype === "projectConfig") {
        setInputFiles({
          ...inputFiles,
          project_config: {
            filename,
            file: "loaded",
          },
        });
        setSelectedFiles([...selectedFiles, file]);
      } else if (filetype === "config") {
        setInputFiles({
          ...inputFiles,
          config: {
            filename,
            file: "loaded",
          },
        });
        setSelectedFiles([...selectedFiles, file]);
      } else if (filetype === "data") {
        setInputFiles({
          ...inputFiles,
          data: {
            filename,
            file: "loaded",
          },
        });
        setSelectedFiles([...selectedFiles, file]);
      } else if (filetype === "geojson") {
        setInputFiles({
          ...inputFiles,
          geojson: {
            filename,
            file: "loaded",
          },
        });
        setSelectedFiles([...selectedFiles, file]);
      }
    }
    
  };

  const validateForm = () => {
    let errorlist = [];
    // validata input files
    if (inputFiles.project_config.file === null) {
      errorlist.push({
        path: "project_config_label",
        msg: "Add project config file.",
      });
    }
    if (inputFiles.config.file === null) {
      errorlist.push({
        path: "config_label",
        msg: "Add data config file.",
      });
    }
    if (inputFiles.data.file === null) {
      errorlist.push({
        path: "data_label",
        msg: "Add data file.",
      });
    }
    if (inputFiles.geojson.file === null) {
      errorlist.push({
        path: "geojson_label",
        msg: "Add geojson file.",
      });
    }

    if (inputdata.name === "" || inputdata.name.length < 2) {
      errorlist.push({
        path: "name",
        msg: "Scenario name should be atleast 2 characters",
      });
    }
    if (inputdata.description === "" || inputdata.description.length < 10) {
      errorlist.push({
        path: "description",
        msg: "Scenario description should be atleast 10 characters",
      });
    }
    setErrorParams(errorlist);
    if (errorlist.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleFormSubmit = async () => {
    try {
      let validation = validateForm();
      if (validation === false) {
        console.log("Validation failed");
        return false;
      } else {
        const formData = new FormData();
        formData.append("name", inputdata.name);
        formData.append("description", inputdata.description);
        selectedFiles.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        if (edit) {
          console.log("edit true");
        } else {
          const res = await axios.post(`${API_URL}/scenario/create`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if(res.data.status === 200 && res.data.success === true){
            navigate('/scenariomanager?action=create&status=success')
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (errorParams.length > 0) {
      errorParams.forEach((err) => {
        let ele = document.getElementById(err.path);
        if (ele) {
          ele.style.border = "1px solid red";
        }
      });
    }
  }, [errorParams]);

  return (
    <form
      className="scenarioForm"
      onSubmit={(e) => {
        e.preventDefault();
        handleFormSubmit();
      }}
    >
      <div className="fileInputContainer">
        {/* 
      This section should take input file.
      Possibility of restricting the file format
      
      */}
        <label
          className="card"
          htmlFor="projConfFile"
          id="project_config_label"
        >
          {inputFiles.project_config.file === null ? (
            <>
              <BsFileEarmarkPlus className="icon" />
              <span className="label">(JSON)</span>
              <span className="label">Upload Project Config File</span>
            </>
          ) : (
            <>
              <LuFileJson className="icon" />
              <span className="label">
                {inputFiles.project_config.filename}
              </span>
            </>
          )}
        </label>
        <input
          hidden
          type="file"
          accept=".json"
          id="projConfFile"
          onChange={(e) => {
            handleFileLoad(e, "projectConfig");
            monitorInputChange("project_config_label");
          }}
        />
        <label className="card" htmlFor="config" id="config_label">
          {inputFiles.config.file === null ? (
            <>
              <BsFileEarmarkPlus className="icon" />
              <span className="label">(CSV)</span>
              <span className="label">Upload Config File</span>
            </>
          ) : (
            <>
              <BsFiletypeCsv className="icon" />
              <span className="label">{inputFiles.config.filename}</span>
            </>
          )}
        </label>
        <input
          hidden
          type="file"
          accept=".csv"
          id="config"
          onChange={(e) => {
            handleFileLoad(e, "config");
            monitorInputChange("config_label");
          }}
        />
        <label className="card" htmlFor="data" id="data_label">
          {inputFiles.data.file === null ? (
            <>
              <BsFileEarmarkPlus className="icon" />
              <span className="label">(CSV)</span>
              <span className="label">Upload Data File</span>
            </>
          ) : (
            <>
              <BsFiletypeCsv className="icon" />
              <span className="label">{inputFiles.data.filename}</span>
            </>
          )}
        </label>
        <input
          hidden
          type="file"
          accept=".csv"
          id="data"
          onChange={(e) => {
            handleFileLoad(e, "data");
            monitorInputChange("data_label");
          }}
        />
        <label className="card" htmlFor="geojson" id="geojson_label">
          {inputFiles.geojson.file === null ? (
            <>
              <BsFileEarmarkPlus className="icon" />
              <span className="label">(GEOJSON)</span>
              <span className="label">Upload GeoJSON File</span>
            </>
          ) : (
            <>
              <LuFileJson className="icon" />
              <span className="label">{inputFiles.geojson.filename}</span>
            </>
          )}
        </label>
        <input
          hidden
          type="file"
          accept=".geojson"
          id="geojson"
          onChange={(e) => {
            handleFileLoad(e, "geojson");
            monitorInputChange("geojson_label");
          }}
        />
      </div>

      <div className="inputHolder">
        <span>Scenario Name</span>
        <input
          type="text"
          placeholder="Enter scenario name"
          id="name"
          value={inputdata.name}
          onChange={(e) => {
            setInputdata({
              ...inputdata,
              name: e.target.value,
            });
            monitorInputChange("name");
          }}
        />
      </div>

      <div className="textareaHolder">
        <span>Scenario Description</span>
        <textarea
          rows="10"
          placeholder="Enter scenario description"
          id="description"
          onChange={(e) => {
            setInputdata({
              ...inputdata,
              description: e.target.value,
            });
            monitorInputChange("description");
          }}
          value={inputdata.description}
        ></textarea>
      </div>
      <div className="btnContainer">
        <div className="holder1">
          <button
            type="button"
            onClick={() => {
              navigate("/scenariomanager");
            }}
          >
            Cancel
          </button>
          <button type="submit">Submit</button>
        </div>
        {edit ? (
          <div className="holder2">
            <button type="button">
              <MdDeleteOutline /> Delete
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </form>
  );
};

export default ScenarioForm;
