import React, { useState, useEffect } from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import "./scenarioManager.scss";
import axios from "axios";
import {notification} from "antd";

// component
import DataTable from "../../components/UI/DataTable/DataTable";
import AppBar from "../../components/UI/AppBar/AppBar";

// svg
import loadingImg from "../../assets/svg/loading-animate.svg";

// icons
import { IoAdd } from "react-icons/io5";

const API_URL = "/api/v1"

const handleScenrioData = (scenarios) => {
  const newlist = [];
  if (Array.isArray(scenarios)) {
    scenarios.forEach((scene, index) => {
      let rowdata = [
        {
          key: "id",
          name: "_id",
          value: scene._id,
        },
        {
          key: 0,
          name: "index",
          value: index + 1,
        },
        {
          key: 1,
          name: "Scenario",
          value: scene.name,
        },
        {
          key: 2,
          name: "Description",
          value: scene.description,
        },
      ];
      newlist.push(rowdata);
    });
  }
  return newlist;
};



// Decoupled this one to get rid of Either include it or remove the dependency array  react-hooks/exhaustive-deps
const getScenarios = async (setScenarios, setLoading,navigate) => {
  try {
    const res = await axios.get(`${API_URL}/scenario/list`);
    if (res.data.status === 200 && res.data.success === true) {
      if (res.data.scenarios.length > 0) {
        const reStructScene = handleScenrioData(res.data.scenarios);
        setLoading(false)
        setScenarios(reStructScene);
      }
    }
  } catch (error) {
    if (error.response.data.status === 401 && error.response.data.success === false) {
      navigate('/');
    }
  }
};

const ScenarioManager = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [api, contextHolder] = notification.useNotification()
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const datacolumn = ["Scenario", "Description"];

  const action = searchParams.get("action");
  const status = searchParams.get("status");

  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description
    });
  };

  const handleGetScenarioDetail = (id) => {
    navigate(`/scenariomanager/info?id=${id}`)
  };

  const handleNavigateAddScenario = () =>{
    navigate("/scenariomanager/add");
  }

  useEffect(() =>{
    if(action === "create" && status ==="success"){
      openNotificationWithIcon('success', 'Scenario Created', 'New scenario created successfully.')
    }
    if(action === "delete" && status ==="success"){
      openNotificationWithIcon('info', 'Scenario Deleted', 'Scenario deleted successfully.')
    }
  },[action])

  useEffect(() => {
      getScenarios(setScenarios, setLoading, navigate);
  }, [navigate]);

  return (
    <div className="scenarioManager">
      {contextHolder}
      <AppBar 
        title="Scenario Manager"
        pageType="page"
        actionFunc={handleNavigateAddScenario}
        icon={
          <IoAdd className="add" />
        }
        text="Add Scenario"
      />
      {
          scenarios.length > 0 ? (
      <div className="listContainer">
            {loading ? (
              <div className="loadingContainer">
                  <img src={loadingImg} alt="loading animation" />
              </div>
            ) : (
              <DataTable
                datacolumn={datacolumn}
                datasource={scenarios}
                handleClick={handleGetScenarioDetail}
              />
            )
            }
      </div>
      )
          : ("Scenario collection looks empty.")
        }
    </div>
  );
};

export default ScenarioManager;
