import { useState, useEffect } from "react"


function getCircleXPosition(allEnergyValues, currentStep){
  // console.log(allEnergyValues)
  // console.log(currentStep)
  const energyRange = Math.max(...allEnergyValues) - Math.min(...allEnergyValues)
  const pixelsPerUnitInRange = 140 / energyRange // 150 should be a const - basically it depends on div size for the asset box in popup
  const currentX = pixelsPerUnitInRange * (allEnergyValues[currentStep]-Math.min(...allEnergyValues)) 
  return currentX
}

export default function PopupComponent({ node, node_name, nodeAssetData, assetsPowerData, currentStep, interval, polygonCoord }){
    const [assetsPowerObj, setAssetsPowerObj] = useState(assetsPowerData)
    const [i, setI] = useState(currentStep)

    useEffect(() => {
        // console.log(nodeAssetData.length)
        const timer = setInterval(() => {
              var newAssetsPowerObj = {}
              for (let[asset_power, valuesDict] of Object.entries(assetsPowerObj)){
                valuesDict.currentEnergyValue = valuesDict.energyValues[i%valuesDict.energyValues.length]
                valuesDict.currentX = getCircleXPosition(valuesDict.energyValues, [i%valuesDict.energyValues.length])
                newAssetsPowerObj[asset_power] = valuesDict
              }
              setAssetsPowerObj(newAssetsPowerObj)
       
              
          setI(i+1);
        }, interval);
 
        //Clearing the interval
        return () => clearInterval(timer);
    // Disabled eslint rule for exhaustive deps. 
    // The assetsPowerObj and interval are not expected to change over the lifecycle of this component. 
    // This approach keeps the interval focused on updating based on the current iteration i which is its only purpose.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i]);
// console.log(assetsPowerData)
// console.log(assetsPowerObj)

  const valuesArr = Object.values(assetsPowerObj)
  const elementsArr = valuesArr.map((valuesDict, index) => {
  return (  
      <div className="asset" key={valuesDict.asset+valuesDict.energy_type}>
        <div className="asset-data" >
          {/* <h4 className="row-title">Node Name</h4> */}
          <p className="node-name">Node: {node_name.toUpperCase()}</p>
          <p className="asset-name">Asset: {valuesDict.asset.toUpperCase()}</p>
          <p className="energy-type">Measurment : {valuesDict.energy_type==='el_power'? 'Electric P' : (valuesDict.energy_type==='th_power'? 'Thermal P': 'CO Emissions')}</p>
        </div>
        <div className="energy-value">Value : {valuesDict.currentEnergyValue}</div>
        <svg name="viewport" width='165' height='30' xmlns="http://www.w3.org/2000/svg">

          <defs>
            <marker
              id="arrow"
              viewBox="0 0 10 10"
              refX="5"
              refY="5"
              markerWidth="6"
              markerHeight="6"
              orient="auto-start-reverse">
              <path d="M 0 0 L 10 5 L 0 10 z" />
            </marker>
          </defs>

          <circle r="5" fill='red' cx={valuesDict.currentX+7} cy='15'/>
          <line x1='0' x2='155' y1='15' y2='15' strokeDasharray="5,3" stroke='gray'  markerEnd="url(#arrow)"/>
          <text x="0" y="27" className="number">{Math.min(...valuesDict.energyValues)}</text>
          <text dx="140" dy="27" className="number">{Math.max(...valuesDict.energyValues)}</text>
        </svg>
        <div></div>
        {/* <svg name="viewport" width='165' height='80' xmlns="http://www.w3.org/2000/svg">
          <polygon points={polygonCoord} fill="none" stroke="salmon" strokeWidth={2} />
        </svg> */}
      </div>
  )
})

return (
  <div>
    <svg name="viewport" width='165' height='70' xmlns="http://www.w3.org/2000/svg">
        <polygon points={polygonCoord} fill="none" stroke="salmon" strokeWidth={2} />
    </svg>
    <div className="popup" style={{display: 'flex', flexDirection: 'row'}}>
      {elementsArr}
    </div>
  </div>
  )
}
