import React, { useEffect, useState } from "react";
import "./map.scss";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Map from "../../components/Map/scenarioMap";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import LoadingMap from "../../components/Map/loadingMap";
import { BounceLoader } from 'react-spinners';

const API_URL = "/api/v1";

const animationLookup = ["filled circles", "circles", "charging lines", "snake lines"];

export default function MapVub() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get("id") || "";
  const animation = searchParams.get("animation") || "";
  const siteCoord = {
    lng: searchParams.get('lng') || 4.2927, 
    lat: searchParams.get('lat') || 50.8814, 
    zoom: searchParams.get('zoom') || 17.71,
    bearing: searchParams.get('bearing') || 15
  };

  const [scenarioData, setScenarioData] = useState({
    scenarioName: "",
    assetsData: [],
    config: [],
    geojson: {},
    projectConfig: {},
    analytics: { nodeInfoPanelData: {}, generalInfoPanelData: [] },
  });

  // now controlling initial loading animation and data fetching separately
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const nodeRef = React.useRef(null);

  useEffect(() => {
    const getScenario = async () => {
      if (id === "" || animation === "") {
        navigate("/map");
        return;
      }

      if (!animationLookup.includes(animation)) {
        navigate("/map");
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/scenario/detail/${id}`);
        if (response.status === 200) {
          const generalPanelResponse = await axios.get(`${API_URL}/scenario/generalinfodata/${id}`);
          const generalInfoPanelData = generalPanelResponse.status === 200 ? generalPanelResponse.data.generalInfoPanelData : [];
          setScenarioData({
            scenarioName: response.data.name,
            assetsData: response.data.assetsData,
            config: response.data.config,
            geojson: response.data.geojson,
            projectConfig: response.data.projectConfig,
            analytics: { nodeInfoPanelData: {}, generalInfoPanelData },
          });
          setDataLoaded(true);
        }
      } catch (error) {
        console.error(error);
        navigate("/map");
      }
    };

    getScenario();
  }, [id, animation, navigate]);

  useEffect(() => {
    const updateDimensions = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const renderContent = () => {
    if (loading) {
      return <LoadingMap siteCoord={siteCoord} onAnimationEnd={() => setLoading(false)} />;
    } else if (!dataLoaded) {
      // data hasn't loaded after the LoadingMap animation ends(slow connection) -> show spinner
      return (
        <div className="loading-container">
          <BounceLoader color="#ffffff" />
        </div>
      );
    } else {
      // now in that final case data is loaded and validated, show the Map
      return (
        <Map
          scenarioName={scenarioData.scenarioName}
          scenarioData={scenarioData}
          animation={animation}
          screenHeight={screenHeight}
          screenWidth={screenWidth}
        />
      );
    }
  };

  return (
    <div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={loading ? "loadingMap" : "mapContent"}
          nodeRef={nodeRef}
          in
          timeout={300}
          classNames="fade"
        >
          <div ref={nodeRef}>
            {renderContent()}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}
