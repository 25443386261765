import "./generalInfoPanel.scss";
import triangle from "../../../assets/demo/triangle.png";
import cloud from "../../../assets/demo/cloud.png";
import sun from "../../../assets/demo/sun.png";

export default function GeneralPanelClosed(){

    return (
        <div className="closedGeneralPanelWrapper">
            <div className='monetary'>
                <img src={triangle} alt="money" width="60" height="60"/>
            </div>
            <div className='pollution'>
                <img src={cloud} alt="pollution" width="60" height="60"/>
            </div>
            <div className='energy'>
                <img src={sun} alt="energy" width="60" height="60"/>
            </div>
        </div>
    )
}