import { lineString } from '@turf/turf';
import Papa from 'papaparse';
import projectJson from '../../data/demo/projectConfig.json';
import {Combination} from 'js-combinatorics';


// willneed to be overwritten because the data structure will be different (maybe can even calculate this object in API)
export function getAssetsDataForGeneralInfoPanel(assetsData){
    // code just for demo 
    const numberOfObservations = assetsData.filter((item)=>item.tag==='main.grid.el_power').length
    const measurements = ['el_power', 'th_power']
    var filteredAssetsData = assetsData.filter((item)=>item.asset==='main'&&measurements.includes(item.energy_type))
    var newAssetsData = []
    for (let i=0; i<filteredAssetsData.length; i++){
        var newDataObj = {...assetsData[i], range: (i%numberOfObservations)+1}
        newDataObj.agg_tag = newDataObj.energy_type + '_'+ newDataObj.range
        newAssetsData.push(newDataObj)
    }
    var ranges = Array.from(new Set(newAssetsData.map((item)=>item.range)).values())
    ranges.sort(function(a, b) {
        return a - b;
      });

    
    var infoPanelData = []
    for (let range of ranges){
        var rangeData = []
        for (let measure of measurements){
            const aggregatedValue = newAssetsData.filter((item)=>item.range===range && item.energy_type===measure).map((item)=>item.energy).reduce((partialSum, a) => partialSum + a, 0);
            rangeData.push({energy_type: measure, energy: aggregatedValue})
        }
        infoPanelData.push(rangeData)
    }
    return infoPanelData
}

export function getMainGridLine(data){
    const filteredRoutes = data.features.filter((feature)=>feature.properties.route==='grid');
    console.log(filteredRoutes)
    return {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': filteredRoutes
        } 
    }
}

export function getMainGridLineSections(data){
    const mainGridLine = getMainGridLine(data)
    var sections = []
    mainGridLine.data.features.forEach(
        (feature)=> {
            var allCoordinates = feature.geometry.coordinates         
            for (let i=0; i<allCoordinates.length-1; i++){
                sections.push(lineString([allCoordinates[i], allCoordinates[i+1]]))
            }
        }
    )
    return sections
}

export function getNodeToGridLines(data){
    const nodeToGridPoints =  data.features.filter((feature)=>feature.properties.route_type==='node_to_grid');
    // console.log(nodeToGridPoints)
    const routesArr = Array.from(new Set(nodeToGridPoints.map((point)=>point.properties.route)).values())
    var lineFeaturesArr = [] 
    for (let route of routesArr){
        const routePoints = nodeToGridPoints.filter((point)=>point.properties.route===route)
        const line = lineString([routePoints.find((point)=>point.properties.point_type==='origin').geometry.coordinates, 
                                 routePoints.find((point)=>point.properties.point_type==='destination').geometry.coordinates])
        line.properties['power_type'] = routePoints[0].properties.power_type;
        line.properties['node'] = routePoints[0].properties.node;
        // line.properties['color'] = line.properties['power_type']==='el_power'? 'salmon' : '#71c7ec'; // get colors into the properties config file
        line.properties['color'] = projectJson.grid_color_power_type[line.properties['power_type']]
        // console.log(line)
        lineFeaturesArr=lineFeaturesArr.concat(line)
    }
    // console.log(lineFeaturesArr)
    return {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': lineFeaturesArr
        } 
    }
}

function findPointsOnLine(pointFrom, pointTo, movingLineHeight) {
    // Calculate the distance between (x1, y1) and (x2, y2)
    const distance = Math.sqrt(Math.pow(pointFrom.x - pointTo.x, 2) + Math.pow(pointFrom.y - pointTo.y, 2));
    
    // Calculate the ratio
    const ratioStop1 = movingLineHeight / distance;
    const ratioStop2 = (distance - movingLineHeight) / distance;
    
    // Calculate the coordinates of the point
    const x1 = pointFrom.x + (pointTo.x - pointFrom.x) * ratioStop1;
    const y1 = pointFrom.y + (pointTo.y - pointFrom.y) * ratioStop1;
    
    const x2 = pointFrom.x + (pointTo.x - pointFrom.x) * ratioStop2;
    const y2 = pointFrom.y + (pointTo.y - pointFrom.y) * ratioStop2;
    
    return {stop1: {x: x1, y: y1}, stop2:  {x: x2, y: y2}, animationRatio: ratioStop1};
}

// Example usage:

export function getAnimationPointsRoutes(data, assetsData, mapRef){
    const nodeToGridPoints =  data.features.filter((feature)=>feature.properties.route_type==='node_to_grid');
    const routesArr = Array.from(new Set(nodeToGridPoints.map((point)=>point.properties.route)).values());

    var animatedRoutesArr = []
    // var animatedPointsSetsArr = []
    const movingLineHeight = projectJson.moving_line_height // will be parameter
    
    for (let route of routesArr){
        const routePoints = nodeToGridPoints.filter((point)=>point.properties.route===route);
        const node_asset_energy = routePoints[0].properties.node+'.main.'+routePoints[0].properties.power_type

        if (assetsData.filter((data) => data.tag === node_asset_energy).length===0){
            continue;
        }

        const energyArr = assetsData.filter((data) => data.tag === node_asset_energy).map((data) => data.energy);
        const averageEnergy = energyArr.reduce((partialSum, a) => partialSum + a, 0) / energyArr.length // for direction
        const duration = Math.abs(averageEnergy) <=10 ? 5000 : (Math.abs(averageEnergy) <= 80 ? 3000 : 1000 )// provisional - not needed eventually because reassign durations later

        const pointFrom = averageEnergy >= 0 ? mapRef.project(routePoints.find((point)=>point.properties.point_type==='origin').geometry.coordinates) :
                                               mapRef.project(routePoints.find((point)=>point.properties.point_type==='destination').geometry.coordinates)
        
        const pointTo = averageEnergy >= 0 ? mapRef.project(routePoints.find((point)=>point.properties.point_type==='destination').geometry.coordinates) :
                                             mapRef.project(routePoints.find((point)=>point.properties.point_type==='origin').geometry.coordinates)

        const stopPointsOnLine = findPointsOnLine(pointFrom, pointTo, movingLineHeight)

        const routeObj = {id: route, from: pointFrom, to: pointTo,  duration: duration,  color: projectJson.circle_color_power_type[routePoints[0].properties.power_type], 
                          tag: node_asset_energy, node: routePoints[0].properties.node, toStop1: stopPointsOnLine.stop1, toStop2: stopPointsOnLine.stop2, 
                          animationRatio: stopPointsOnLine.animationRatio}                                           

        animatedRoutesArr.push(routeObj)
    }

    var animatedPointsObj = {}
    // getting the number of sets of points
    const numberOfObservations = assetsData.filter((item)=>item.tag==='main.grid.el_power').length
    for (let n = 0; n<numberOfObservations; n++){
         var arrCopy = [] 
         for (let routeData of animatedRoutesArr){
            var newRouteData = JSON.parse(JSON.stringify(routeData))
            const energyValue = assetsData.filter((data)=>data.tag === routeData.tag)[n].energy
            const circleRadius = energyValue===0 ? 0: Math.log10(Math.abs(energyValue))*3 //maybe some other transformation
            newRouteData['size'] = circleRadius

            const co_emissions_row = assetsData.filter((data)=>data.tag===routeData.node+'.main.co_emissions_level')[n]
            const co_emissions_value = co_emissions_row ? co_emissions_row.energy : 0
            const co_emissions_color = projectJson.co_emissions_color_map[co_emissions_value]
            newRouteData['co_emissions_color'] = co_emissions_color

            const newDuration = Math.abs(energyValue) <=10 ? 5000 : (Math.abs(energyValue) <= 80 ? 3000 : 1000 )
            newRouteData['duration'] = newDuration

            arrCopy.push(newRouteData)
         }
     
         animatedPointsObj[n] = arrCopy
    }

    // console.log(animatedPointsObj)
    return {points_object : animatedPointsObj, routes: animatedRoutesArr}
}


// Data Loader functions
export async function getAssetsData(){ // make one function with url as a parameter
    return new Promise(resolve => {
        Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vQb_n6lHnPTExh9BNk62JlcgxbaAJqEgELWAcugdZPmqlXcd0i1LJP0qfzmb4km5EwUk8lNUkSDUZ1_/pub?gid=0&single=true&output=csv',
         {
          download: true, header: true, dynamicTyping: true,
          complete: results => {
            console.log('Complete', results.data.length, 'records.'); 
            resolve(Array.from(results.data));
          }
        });
    });
}
// Data Loader functions
export async function getAssetsConfigData(){
    return new Promise(resolve => {
        Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vSgjyO_0_tLbnZPa5s-OhLC6pdEyAvLQTuisOGov4cdJVw_UR437TucP-7-vAffJEa7Oep6XW1xkktL/pub?gid=1897571917&single=true&output=csv',
         {
          download: true, header: true, dynamicTyping: true,
          complete: results => {
            console.log('Complete', results.data.length, 'records.'); 
            resolve(Array.from(results.data));
          }
        });
    });    
}



// Popup related function
export function getCircleXPosition(allEnergyValues, currentStep){
    const energyRange = Math.max(...allEnergyValues) - Math.min(...allEnergyValues)
    const pixelsPerUnitInRange = 150 / energyRange // 150 should be a const - basically it depends on div size for the asset box in popup
    const currentX = pixelsPerUnitInRange * (allEnergyValues[currentStep]-Math.min(...allEnergyValues)) 
    return currentX
  }
  
// Popup related function
export function getAssetsPowerObj(nodeAssetData, currentStep){
    var returnObj = {}
    const newNodeAssetData = nodeAssetData.map((data)=>{return{...data, asset_power: data.asset+'.'+data.energy_type}})
    const assetPowerValues = Array.from(new Set(newNodeAssetData.map((data)=>data.asset_power)).values())
    for (let apValue of assetPowerValues){
      const allEnergyValues = newNodeAssetData.filter((data)=>data.asset_power===apValue).map((data)=>data.energy)
      const currentX = getCircleXPosition(allEnergyValues, currentStep)
      
      returnObj[apValue] = {energyValues: allEnergyValues,
                            currentEnergyValue: allEnergyValues[currentStep],
                            currentX: currentX,
                            asset: newNodeAssetData.find((data)=>data.asset_power===apValue).asset,
                            energy_type: newNodeAssetData.find((data)=>data.asset_power===apValue).energy_type
                          }
    }
    // console.log(returnObj)
    return returnObj
  }

  export function getAllNodesForLayers(data){
    const allPolygons = data.features.filter((feature)=>feature.geometry.type==='Polygon');
    const nodesArr = Array.from(new Set(allPolygons.map((poly)=>poly.properties.node)).values());
    
    var buildingsArr = []

    for (let node of nodesArr){
        const nodeData = allPolygons.find((poly)=>poly.properties.node===node);
        // console.log(nodeData)
        const building = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': nodeData.geometry.coordinates
                    },
                    'properties': {
                        'node': nodeData.properties.node,
                        'color': 'black'
                    }
                }
            ]
        }
        const buildingObj = {id: nodeData.properties.node, polygon: building}
        buildingsArr.push(buildingObj)
    }
    return buildingsArr
  }

  export function getPolygonsProjectionsObj(mapRef, data){
    const allNodes = data.features.filter((feature)=>(feature.geometry.type==='Polygon')).map((item)=>item.properties.node)
    var polygonsProjectionObj = {}
    for (let building of allNodes){
        polygonsProjectionObj[building] = getPolygonSvgCoord(mapRef, data, building)
    }
    return polygonsProjectionObj
  }

  export function getPolygonSvgCoord(mapRef, data, node){
    const maxHeight = 50 // should be somewhere in consts
    const building = data.features.find((feature)=>(feature.geometry.type==='Polygon')&& (feature.properties.node===node)); 
    // console.log(building)
    const polygonPointsInPx = building.geometry.coordinates[0].map((coord)=>mapRef.project(coord)) // array of Mapbox Point objects
    // console.log(polygonPointsInPx)
    const combinationsOfPoints = new Combination(polygonPointsInPx, 2).toArray();
    // console.log(combinationsOfPoints)
    // all this can be calculated once when we initalize map and put to obj => then on PopUp we just reference the object
    const pointsDistancesArr = combinationsOfPoints.map((combination)=>        
         Math.sqrt((combination[0].x - combination[1].x)**2 + (combination[0].y - combination[1].y)**2)
    )
    // console.log(pointsDistancesArr)
    const maxDistance = Math.max(...pointsDistancesArr)
    const coordConversionRatio = maxHeight/maxDistance
    var pointsForSvg = polygonPointsInPx.map((vertex)=>[vertex.x*coordConversionRatio, vertex.y*coordConversionRatio])
    const minX = Math.min(...pointsForSvg.map((vertex)=>vertex[0]))
    const minY = Math.min(...pointsForSvg.map((vertex)=>vertex[1]))
    pointsForSvg = pointsForSvg.map((vertex)=>[vertex[0]-minX, vertex[1]-minY])
    const polygonVertexString = pointsForSvg.map((vertex)=>vertex[0].toString()+','+vertex[1].toString()).join(' ')
    // const indexMaxDist = pointsDistancesArr.indexOf(maxDistance)
    return polygonVertexString

  }