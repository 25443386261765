import "./generalInfoPanel.scss";
import MeasurementCard from "./measurementCard";
import React from "react";

export default function GeneralPanelOpen({ generalInfoDataRow, projectConfig }) {
  const { grids, measurements } = generalInfoDataRow;

  return (
    <div className="openGeneralPanelWrapper">
      <MeasurementCard measurementDataObj={{ measurements }} isFirstColumn={true} projectConfig={projectConfig} />
      {/* <div className="verticalLineInfoPanel"></div> */}
      {grids.map((gridData, index) => (
        <React.Fragment key={index}>
          <MeasurementCard measurementDataObj={gridData} isFirstColumn={false} projectConfig={projectConfig} />
          {/* {index < grids.length - 1 && <div className="verticalLineInfoPanel"></div>} */}
        </React.Fragment>
      ))}
    </div>
  );
}