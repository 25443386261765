import "./generalInfoPanel.scss";
import Co2Svg from "../SvgIcons/co2";
import EnergyBalanceSvg from "../SvgIcons/energyBalance";
import MonetarySvg from "../SvgIcons/monetary";

export default function GeneralPanelClosed() {
  return (
    <div className="closedGeneralPanelWrapper">
      <div className="monetary">
        <MonetarySvg color={"white"} />
      </div>
      <div className="pollution">
        <Co2Svg color={"white"} />
      </div>
      <div className="energy">
        <EnergyBalanceSvg color={"white"} />
      </div>
    </div>
  );
}
