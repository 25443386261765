import React, { useState } from "react";
import "./legend.scss";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

export default function Legend({ projectConfig }) {
  const [isOpen, setIsOpen] = useState(false);
  const colorMap = projectConfig.node_color_map;
  const codes = Object.keys(colorMap);
  const nodeCodeToName = projectConfig.node_color_code_to_names_map
    ? projectConfig.node_color_code_to_names_map
    : {
      0: "not in use",
      100: "no calculation",
      200: "good",
      300: "moderate",
      400: "bad",
    };

  const elements = codes.map((code) => (
    <div key={code} className="valueWrapper">
      <div className="color" style={{ backgroundColor: colorMap[code] }}></div>
      <div className="explanation">{nodeCodeToName[code]}</div>
    </div>
  ));

  function toggleOpenClose() {
    setIsOpen((prevValue) => !prevValue);
  }

  return (
    <div className="legendContainer">
      <div className="legendTitle">
        Color Legend{" "}
        {isOpen ? (
          <IoIosArrowUp className="iconLegend" onClick={toggleOpenClose} />
        ) : (
          <IoIosArrowDown className="iconLegend" onClick={toggleOpenClose} />
        )}
      </div>
      {isOpen && elements}
    </div>
  );
}
