import { useSprings, animated } from 'react-spring';


export function SnakeLinesComponent({ animatedItemsDataArr, screenHeight, screenWidth, selectedFlows }) {
  const animatedItems = animatedItemsDataArr.filter((route) => selectedFlows.includes(route.route_power_type))

  var springs = useSprings(
    animatedItems.length,
    animatedItems.map((route) => ({
      from: { x1: route.from.x, y1: route.from.y, x2: route.from.x, y2: route.from.y }, // Initial coordinates for each set of points
      to: async next => {
        await next({ x2: route.to.x, y2: route.to.y, config: { duration: route.duration / 2 } });
        await next({ x1: route.to.x, y1: route.to.y, config: { duration: route.duration / 2 } });
      },
      loop: true, // Enable looping animation
    }))
  )


  const renderAnimatedCircles = () => {
    return springs.map((props, index) => {
      return (
        <animated.line key={index} {...props} stroke={animatedItems[index].color} strokeWidth={animatedItems[index].size} />
      )
    });
  };

  return (<svg pointerEvents="none" width={screenWidth} height={screenHeight * 0.8} style={{ position: 'absolute', zIndex: 99 }}>
    {renderAnimatedCircles()}
  </svg>)

}
