import React, { useState, useEffect } from 'react';
import "./generalInfoPanel.scss";
import GeneralPanelClosed from './generalInfoPanelClosed';
import GeneralPanelOpen from './generalnfoPanelOpen';
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";

export default function GeneralInfoPanel({ isPanelOpen, generalInfoDataRow, handleOpenClose, panelPosition, projectConfig }) {

    const [isOpen, setIsOpen] = useState(isPanelOpen)
    const [isDataReady, setIsDataReady] = useState(false);

    useEffect(() => {
        if (generalInfoDataRow) {
            setIsDataReady(true);
        }
    }, [generalInfoDataRow]);

    function toggleOpenClose() {
        setIsOpen(!isOpen);
        handleOpenClose(!isOpen);
    }


    return (
        <div className={`generalInfoPanelWrapper${panelPosition} ${isOpen ? 'open' : 'closed'}`}>
            {isOpen && isDataReady ? (
                <GeneralPanelOpen generalInfoDataRow={generalInfoDataRow} projectConfig={projectConfig} />
            ) : (

                <GeneralPanelClosed />
            )}
            <div className="openCloseButtonGeneralPanel" onClick={toggleOpenClose}>
                {isOpen ? <FaAngleLeft size={'2.5vh'} /> : <FaAngleRight size={'2.5vh'} />}
            </div>
        </div>
    );
}