import "./generalInfoPanel.scss";
import MeasurementCard from "./measurementCard";

export default function GeneralPanelOpen({generalInfoDataRow}){
    console.log(generalInfoDataRow)

    return (
        <div className="openGeneralPanelWrapper">
           {generalInfoDataRow.map((measurement, index) => 
               <MeasurementCard key={index} measurementDataObj={measurement}/>
           )}
        </div>
    );
}