import { useSprings, animated } from 'react-spring';


 export function CirclesComponentMultisprings({circlesDataArr, screenHeight, screenWidth}){
    var springs = useSprings(
        circlesDataArr.length,
        circlesDataArr.map((route)=>({
          from: { cx: route.from.x, cy: route.from.y, strokeOpacity: 1 }, // Initial coordinates for each set of points
          to: { cx: route.to.x, cy: route.to.y, strokeOpacity: 0.4 }, // Final coordinates for each set of points
          config: { duration: route.duration}, // Duration for each set of points (adjust as needed)
          loop: true, // Enable looping animation
        })) 
    )
  

    const renderAnimatedCircles = () => {
      return springs.map((props, index) =>{  return (
        <animated.circle key={index} {...props} r={circlesDataArr[index].size} stroke={circlesDataArr[index].color} strokeWidth={2} fill="none"/>
      )});
    };
  
    return (<svg pointerEvents="none" width={screenWidth} height={screenHeight} style={{ position: 'absolute', zIndex: 99 }}>
      {renderAnimatedCircles()}
    </svg>)
  
  }


  export function CirclesComponentMultispringsThreeCircles({circlesDataArr, screenHeight, screenWidth}){

    var springs = useSprings(
        circlesDataArr.length,
        circlesDataArr.map((route)=>({
          from: { cx: route.from.x, cy: route.from.y, strokeOpacity: 1 }, // Initial coordinates for each set of points
          to: { cx: route.to.x, cy: route.to.y, strokeOpacity: 0.4 }, // Final coordinates for each set of points
          config: { duration: route.duration}, // Duration for each set of points (adjust as needed)
          loop: true, // Enable looping animation
        })) 
    )

    var springs2 = useSprings(
      circlesDataArr.length,
      circlesDataArr.map((route)=>({
        from: { cx: route.from.x-5, cy: route.from.y-5, strokeOpacity: 1 }, // Initial coordinates for each set of points
        to: { cx: route.to.x-5, cy: route.to.y-5, strokeOpacity: 0.4 }, // Final coordinates for each set of points
        config: { duration: route.duration}, // Duration for each set of points (adjust as needed)
        loop: true, // Enable looping animation
      })) 
  )

    var springs3 = useSprings(
      circlesDataArr.length,
      circlesDataArr.map((route)=>({
        from: { cx: route.from.x+5, cy: route.from.y-5, strokeOpacity: 1 }, // Initial coordinates for each set of points
        to: { cx: route.to.x+5, cy: route.to.y-5, strokeOpacity: 0.4 }, // Final coordinates for each set of points
        config: { duration: route.duration}, // Duration for each set of points (adjust as needed)
        loop: true, // Enable looping animation
      })) 
  )
  

    const renderAnimatedCircles = (sprArr) => {
      return sprArr.map((props, index) =>{ return (
        <animated.circle key={index} {...props} r={circlesDataArr[index].size} stroke={circlesDataArr[index].color} strokeWidth={2} fill="none"/>
      )});
    };
  
    return (<svg width={screenWidth} height={screenHeight} style={{ position: 'absolute', zIndex: 99 }}>
      {renderAnimatedCircles(springs)}
      {renderAnimatedCircles(springs2)}
      {renderAnimatedCircles(springs3)}
    </svg>)
  
  }


  export function CirclesComponentMultispringsThreeCirclesMerging({circlesDataArr, screenHeight, screenWidth}){

    var springs = useSprings(
        circlesDataArr.length,
        circlesDataArr.map((route)=>({
          from: { cx: route.from.x, cy: route.from.y, strokeOpacity: 1 }, // Initial coordinates for each set of points
          to: { cx: route.to.x, cy: route.to.y, strokeOpacity: 0.4 }, // Final coordinates for each set of points
          config: { duration: route.duration}, // Duration for each set of points (adjust as needed)
          loop: true, // Enable looping animation
        })) 
    )

    var springs2 = useSprings(
      circlesDataArr.length,
      circlesDataArr.map((route)=>({
        from: { cx: route.from.x-5, cy: route.from.y-5, strokeOpacity: 1 }, // Initial coordinates for each set of points
        to: { cx: route.to.x, cy: route.to.y, strokeOpacity: 0.4 }, // Final coordinates for each set of points
        config: { duration: route.duration}, // Duration for each set of points (adjust as needed)
        loop: true, // Enable looping animation
      })) 
  )

    var springs3 = useSprings(
      circlesDataArr.length,
      circlesDataArr.map((route)=>({
        from: { cx: route.from.x+5, cy: route.from.y-5, strokeOpacity: 1 }, // Initial coordinates for each set of points
        to: { cx: route.to.x, cy: route.to.y, strokeOpacity: 0.4 }, // Final coordinates for each set of points
        config: { duration: route.duration}, // Duration for each set of points (adjust as needed)
        loop: true, // Enable looping animation
      })) 
  )
  

    const renderAnimatedCircles = (sprArr) => {
      return sprArr.map((props, index) =>{ return (
        <animated.circle key={index} {...props} r={circlesDataArr[index].size} stroke={circlesDataArr[index].color} strokeWidth={2} fill="none"/>
      )});
    };
  
    return (<svg width={screenWidth} height={screenHeight} style={{ position: 'absolute', zIndex: 99 }}>
      {renderAnimatedCircles(springs)}
      {renderAnimatedCircles(springs2)}
      {renderAnimatedCircles(springs3)}
    </svg>)
  
  }