import React,{useContext} from "react";
import "./navbar.scss";
import { useNavigate } from "react-router-dom";

// context
import { AuthContext } from "../../context/authContext";

// images
import logo from "../../assets/images/vub-logo.png";

// icons
import { IoLogOutOutline } from "react-icons/io5";
import { FaMapLocationDot } from "react-icons/fa6";


const Navbar = () => {
  const {logoutUser} = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const res = await logoutUser();
      if(res.status === 200 && res.success === true){
        navigate("/")
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="navbar">
      <div
        className="logoContainer"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={logo} alt="VUB logo" />
      </div>
      <ul className="menuContainer">
        <li>
          <button className="btn"
            onClick={() =>{
              navigate("/map")
            }}
          >
            <FaMapLocationDot className="icon" /> Map Player
          </button>
        </li>
        <li>
          <button className="btn"
            onClick={handleLogout}
          >
            <IoLogOutOutline className="icon" /> Logout
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
