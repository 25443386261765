import React, { useEffect, useState } from 'react';
import { getAssetsData, getAssetsConfigData } from '../../utils/demo/utiltiesDemoSpring';
import { MapDemo } from '../../components/Demo/mapComponentDemoSpringLines'; // Ensure this is the correct import
import data from '../../data/demo/dataClient.json';
import projectJson from '../../data/demo/projectConfig.json';
import "./demoMap.scss";
import LoadingMap from '../../components/Demo/loadingMap';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

export default function VubDemo() {
  const [assetsData, setAssetsData] = useState(null);
  const [assetsConfig, setAssetsConfig] = useState(null);
  const [geojson, setGeojson] = useState(data);
  const [loading, setLoading] = useState(true);
  const [projectConfigJson, setProjectConfigJson] = useState(projectJson);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [animationOver, setAnimationOver] = useState(false);
  const nodeRef = React.useRef(null);

  const handleAnimationEnd = () => {
    setLoading(false); 
    setAnimationOver(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAssetsData();
        setAssetsData(result);
        const configResult = await getAssetsConfigData();
        setAssetsConfig(configResult);
        setGeojson(data); // Assuming this is placeholder logic
        setProjectConfigJson(projectJson); // Placeholder logic
        setScreenHeight(window.innerHeight / projectJson.display_grid.horizontal);
        setScreenWidth(window.innerWidth / projectJson.display_grid.vertical);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
        <SwitchTransition mode="out-in">
            <CSSTransition
                key={loading ? "loadingMap" : "mapDemo"}
                nodeRef={nodeRef}
                in timeout={300}
                classNames="fade"
            > 
                {/*
                Warning: findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of Transition which is inside StrictMode. Instead, add a ref directly to the element you want to reference. Learn more about using refs safely here: https://fb.me/react-strict-mode-find-node
                Effect: Sometimes after the transition, instead of new component there was a blank screen.
                Solution: https://github.com/reactjs/react-transition-group/issues/668#issuecomment-695162879 
                Added bit: nodeRef={nodeRef} to CSSTransition
                */}
                <div ref={nodeRef}>
                    {loading ? 
                        <LoadingMap onAnimationEnd={handleAnimationEnd} /> : 
                        animationOver && <MapDemo 
                        assetsData={assetsData}
                        assetsConfig={assetsConfig}
                        routesData={geojson}
                        projectConfig={projectConfigJson}
                        screenHeight={screenHeight}
                        screenWidth={screenWidth}
                        />
                    }
                </div>
            </CSSTransition>
        </SwitchTransition>
    </div>
  );
  }