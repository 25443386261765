import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import "./scenarioMap.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import { getPolygonsProjectionsObj, getNodeAssetDataForTimestep, parseTimeToMinutes } from '../../utils/map/utilities';
import { Consts } from '../../utils/map/consts';
import { CirclesComponent } from '../Animations/circlesComponent';
import { ChargingLinesComponent } from '../Animations/chargingLinesComponent';
import { SnakeLinesComponent } from '../Animations/snakelinesComponent';
import {
  getAllBuidingsForLayers, getMainGridLinesLayer, getNodeToGridLinesLayer, getBuildingOutlineLayer,
  getBuildingFillLayer, getAllCpocPoints, getInitialCpocStartingLayer, getInitialCpocOuterLayer, getNodeToGridLinesForSwitchingFlows,
  getMainGridLineForAnimatedFlows, getLayersPerPowerType, getPlaceholderNodesIconDataSource, getLayerForPlaceholderNodesIcons, defineImageMarkerForCpocNodes
} from '../../utils/map/featuresAndLayers';
import { getAnimationRoutesForBuildings, getAverageTimestampForTimestep, getObservationsArr, getNodeColorsArrToLoop, getPowerTypesForNodes, getPlaceholderNodes, getPowerTypesForIcons } from '../../utils/map/utilities';
import Legend from './legend';
import GridSelector from './gridSelectorNew';
import ClockAndLocation from './clockAndLocation';

import { FilledCirclesComponent } from '../Animations/filledCirclesComponent';
import electric from '../../assets/images/gridTypes/electric.svg';
import gas from '../../assets/images/gridTypes/gas_molecule.svg';
import thermal from '../../assets/images/gridTypes/thermal.svg';

import { PiArrowArcRightLight } from "react-icons/pi";
import { PiPlay } from "react-icons/pi";
import { CiPause1 } from "react-icons/ci";
import GeneralInfoPanel from '../Panels/generalnfoPanel';
import NodeInfoPanel from '../Panels/nodeInfoPanel';
import vub_evergi_logo from '../../assets/images/vub_evergi_logo_dark.png';


const gridIconComponentsObj = {
  'th_power': thermal, 'el_power': electric, 'gas_power': gas
}

const cpocIconsObj = {
  'cpoc_el': electric, 'cpoc_th': thermal, 'cpoc_gas': gas
}

const generalPanelIndicatorsToFlowMap = {
  'th_power': 'indicator_th', 'el_power': 'indicator_el', 'gas_power': 'indicator_gas'
}

const animationDict = {
  'charging lines': (props) => <ChargingLinesComponent {...props} />,
  'snake lines': (props) => <SnakeLinesComponent {...props} />,
  'circles': (props) => <CirclesComponent {...props} />,
  'filled circles': (props) => <FilledCirclesComponent {...props} />
}


export default function Map({ scenarioName, scenarioData, animation, screenHeight, screenWidth }) {
  const assetsData = scenarioData.assetsData;
  const assetsConfig = scenarioData.config  // do not remove - we will need it for nodeInfoPanel
  const geoData = scenarioData.geojson
  const projectConfig = scenarioData.projectConfig
  const timestepsArr = getObservationsArr(assetsData)
  const [center_lng, setLng] = useState(projectConfig.centerLng);
  const [center_lat, setLat] = useState(projectConfig.centerLat);
  const [zoom, setZoom] = useState(projectConfig.zoom);
  mapboxgl.accessToken = projectConfig.map_token
  const inter = projectConfig.timestamp_duration // maybe not necessary

  // console.count();
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [animatedPointsToLoop, setAnimatedPointsToLoop] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timeStepsAverage, setTimeStepsAverage] = useState(getAverageTimestampForTimestep(assetsData, 0));
  const [isMapReady, setIsMapReady] = useState(false)
  const [areRoutesCalculated, setAreRoutesCalculated] = useState(false)
  const animationComponent = animationDict[animation]

  const [polygonsObj, setPolygonsObj] = useState({}) // do not remove - we will need it for nodeInfoPanel

  const [currentNodeIndex, setCurrentNodeIndex] = useState(0)
  const [allNodes, setAllNodes] = useState([])

  //for general information panel
  const [isGeneralPanelOpen, setIsGeneralPanelOpen] = useState(true) // do not remove - we will need it for generalInfoPanel
  const [generalInfoPanelData, setGeneralInfoPanelData] = useState(scenarioData.analytics.generalInfoPanelData) // do not remove - we will need it for generalInfoPanel

  // for node information panel
  const [isNodePanelOpen, setIsNodePanelOpen] = useState(false) // do not remove - we will need it for nodeInfoPanel
  // const nodeInfoPanelData = scenarioData.analytics.nodeInfoPanelData // do not remove - we will need it for nodeInfoPanel

  const [nodeColorsArrToLoop, setNodeColorsArrToLoop] = useState([])
  const gridOptions = projectConfig.animated_flows.map((flow) => { return { value: flow, icon: gridIconComponentsObj[flow] } })
  const [selectedFlows, setSelectedFlows] = useState(projectConfig.animated_flows);
  const [layersPowerType, setLayersPowerType] = useState({})
  const [nodesPowerType, setNodesPowerType] = useState([]) // maybe separately
  const placeholderNodes = getPlaceholderNodes(assetsConfig)
  const [iconsForPlacehoderNodes, setIconsForPlacholderNodes] = useState([])
  const [iconsPowerType, setIconsPowerType] = useState([])
  const [sunRise, setSunRise] = useState(parseTimeToMinutes("6:00:00 AM"));
  const [sunSet, setSunSet] = useState(parseTimeToMinutes("6:00:00 PM"));

  function toggleIsGeneralPanelOpen(value) {
    setIsGeneralPanelOpen(!value);
  }

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: projectConfig.map_style,
      center: [center_lng, center_lat],
      zoom: zoom,
      bearing: projectConfig.mapBearing
    });

    const buildings = getAllBuidingsForLayers(geoData)
    const allBuilgingIds = buildings.map((item) => item.id)
    setAllNodes(allBuilgingIds) // only buildings

    const cpocPoints = getAllCpocPoints(geoData, projectConfig)

    const polygonsProjections = getPolygonsProjectionsObj(map.current, geoData)
    setPolygonsObj(polygonsProjections)

    const nodeColorArr = getNodeColorsArrToLoop(timestepsArr, assetsData, projectConfig)
    setNodeColorsArrToLoop(nodeColorArr)

    const powerTypesForNodes = getPowerTypesForNodes(geoData)
    setNodesPowerType(powerTypesForNodes)

    const iconPoints = getPlaceholderNodesIconDataSource(geoData, assetsConfig, projectConfig)
    setIconsForPlacholderNodes(iconPoints)
    setIconsPowerType(getPowerTypesForIcons(iconPoints, powerTypesForNodes))

    map.current.on('move', () => {
      setIsMapReady(false)

      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));

    });

    map.current.on('moveend', () => {
      var animatedPointsSets = getAnimationRoutesForBuildings(geoData, assetsData, map.current, projectConfig, timestepsArr, animation);
      setAnimatedPointsToLoop(animatedPointsSets['points_object'])
    })

    map.current.on('load', () => {
      // adding main grid lines
      const mainGridLinesArr = getMainGridLineForAnimatedFlows(geoData, projectConfig)
      for (let mainGridLine of mainGridLinesArr) {
        map.current.addSource(mainGridLine.id, mainGridLine.gridLine)
        map.current.addLayer(getMainGridLinesLayer(mainGridLine.id))
      }

      const nodeToGridLinesArr = getNodeToGridLinesForSwitchingFlows(geoData, projectConfig)
      for (let nodeToGridLine of nodeToGridLinesArr) {
        map.current.addSource(nodeToGridLine.id, nodeToGridLine.gridLine)
        map.current.addLayer(getNodeToGridLinesLayer(nodeToGridLine.id))
      }

      const layersPowerType = getLayersPerPowerType(mainGridLinesArr.concat(nodeToGridLinesArr), projectConfig)
      setLayersPowerType(layersPowerType)

      for (let building of buildings) {
        map.current.addSource(building.id, { 'type': 'geojson', 'data': building.polygon });
        map.current.addLayer(getBuildingOutlineLayer(building.id, placeholderNodes));
        map.current.addLayer(getBuildingFillLayer(building.id, placeholderNodes))
      }

      for (let cpoc of cpocPoints) {
        map.current.addSource(cpoc.id, { 'type': 'geojson', 'data': cpoc.cpoc })
        map.current.addLayer(getInitialCpocStartingLayer(cpoc.id))
        map.current.addLayer(getInitialCpocOuterLayer(cpoc.id))

        const img = document.createElement('img');
        defineImageMarkerForCpocNodes(img, cpoc.id, cpocIconsObj)
        new mapboxgl.Marker(img).setLngLat(cpoc.cpoc.features[0].geometry.coordinates).addTo(map.current);
      }

      for (let icon of iconPoints) {
        map.current.addSource(icon.id, { 'type': 'geojson', data: icon.iconPoint })
        map.current.addLayer(getLayerForPlaceholderNodesIcons(icon.id))
      }

      //set node colors to the first timestep 
      const currentNodesData = nodeColorArr[0]
      for (let nodeData of currentNodesData) {
        if (!nodeData.node.includes('cpoc')) {  // for buildng outlines
          map.current.setPaintProperty(nodeData.node, 'line-color', nodeData.node_color)
          map.current.setPaintProperty(`${nodeData.node}_fill`, 'fill-color', nodeData.node_color)
        } else { // for cpoc points
          map.current.setPaintProperty(nodeData.node, 'circle-stroke-color', nodeData.node_color)
        }
      }

      setIsMapReady(true);
    })

    var animatedPointsSets = getAnimationRoutesForBuildings(geoData, assetsData, map.current, projectConfig, timestepsArr, animation);
    setAnimatedPointsToLoop(animatedPointsSets['points_object'])
    setAreRoutesCalculated(true)

  }, [center_lat, center_lng, zoom, projectConfig, assetsData, geoData, timestepsArr, animation, placeholderNodes, assetsConfig])

  useEffect(() => {
    const handleKeyPress = (e) => {
      e.preventDefault()

      if (projectConfig.node_switch_keys.includes(e.which)) {
        const prevIndex = currentNodeIndex;
        const nextIndex = currentNodeIndex === allNodes.length - 1 ? 0 : currentNodeIndex + 1;

        map.current.setPaintProperty(allNodes[prevIndex], 'line-width', placeholderNodes.includes(allNodes[prevIndex]) ? Consts.placholder_building_line_width :
          Consts.default_building_line_width);
        map.current.setPaintProperty(allNodes[nextIndex], 'line-width', placeholderNodes.includes(allNodes[nextIndex]) ? Consts.selected_placeholder_building_line_width :
          Consts.selected_building_line_width);
        setCurrentNodeIndex(nextIndex);
      }
    };

    if (map.current !== null) {
      map.current.getCanvas().focus();
      map.current.getCanvas().parentNode.classList.remove('mapboxgl-interactive');
      map.current.getCanvas().addEventListener('keypress', handleKeyPress);
    }

    return () => {
      if (map.current !== null) {
        map.current.getCanvas().removeEventListener('keypress', handleKeyPress);
      }
    };
  }, [currentNodeIndex, allNodes, projectConfig, placeholderNodes]);

  useEffect(() => {
    // Set a timeout to update the text property after 3 seconds
    const timeout = setTimeout(() => {
      // Increment the index to move to the next property

      //adding +1 to current index becuase the data wa already set to zero in the set up stage
      const nextIndex = currentIndex === animatedPointsToLoop.length - 1 ? 0 : currentIndex + 1
      const currentNodesData = nodeColorsArrToLoop[nextIndex]
      for (let nodeData of currentNodesData) {
        if (!nodeData.node.includes('cpoc')) {  // for buildng outlines
          map.current.setPaintProperty(nodeData.node, 'line-color', nodeData.node_color)
          map.current.setPaintProperty(`${nodeData.node}_fill`, 'fill-color', nodeData.node_color)
        } else { // for cpoc points
          map.current.setPaintProperty(nodeData.node, 'circle-stroke-color', nodeData.node_color)
        }
      }
      // Collect the data to be passed to the clockAndLocation component:
      // 1. timeStepsAverage: the average timestamp for each time step
      // 2. name of the scenario -> scenarioName is already here
      // 3. Inside the clockAndLocation component, render an Icon about day or night based on the time of the day
      const timeStepsAvg = getAverageTimestampForTimestep(assetsData, nextIndex);
      setTimeStepsAverage(timeStepsAvg);

      if (isMapReady) {
        setCurrentIndex(prevIndex =>
          prevIndex === animatedPointsToLoop.length - 1 ? 0 : prevIndex + 1
        );
      }
      // }
    }, inter);

    // Clear the timeout when the component unmounts or when properties are updated
    return () => clearTimeout(timeout);
  }, [currentIndex, animatedPointsToLoop.length, animatedPointsToLoop, inter, isMapReady, nodeColorsArrToLoop, assetsData]);

  // Executes the api call only at the time we load the map.
  useEffect(() => {
    fetch(`https://api.sunrisesunset.io/json?lat=${center_lat}&lng=${center_lng}&timezone=UTC+1&date=${getAverageTimestampForTimestep(assetsData, 0).formattedDate}`)
      .then(response => response.json())
      .then(data => {
        setSunRise(parseTimeToMinutes(data.results.sunrise));
        setSunSet(parseTimeToMinutes(data.results.sunset));
      })
      .catch(error => {
        console.error(error);
      });

    return () => {

    }
  }, [center_lat, center_lng, assetsData])


  function handleAnimationStart() {
    setIsMapReady(prevValue =>
      !prevValue
    );
  }

  const handleKeyDownForControls = (event) => {
    event.preventDefault();
  };

  function handleNodeSwitch() {
    const prevIndex = currentNodeIndex
    const nextIndex = currentNodeIndex === allNodes.length - 1 ? 0 : currentNodeIndex + 1
    console.log()

    map.current.setPaintProperty(allNodes[prevIndex], 'line-width', placeholderNodes.includes(allNodes[prevIndex]) ? Consts.placholder_building_line_width :
      Consts.default_building_line_width)
    map.current.setPaintProperty(allNodes[nextIndex], 'line-width', placeholderNodes.includes(allNodes[nextIndex]) ? Consts.selected_placeholder_building_line_width :
      Consts.selected_building_line_width)
    setCurrentNodeIndex(nextIndex)
  }

  const handleGridSelection = (selectedValue) => {
    setIsMapReady(false)
    const newSelectedFlows = selectedFlows.includes(selectedValue) ? selectedFlows.filter((item) => item !== selectedValue) : [...selectedFlows, selectedValue]
    // changing visibility for the grids
    const gridLayers = layersPowerType[selectedValue]
    if (selectedFlows.includes(selectedValue)) {
      gridLayers.forEach((layer) => map.current.setLayoutProperty(layer, 'visibility', 'none'))
    } else {
      gridLayers.forEach((layer) => map.current.setLayoutProperty(layer, 'visibility', 'visible'))
    }

    // switch on/off nodes related to a particular grid
    const nodeLayersMatch = nodesPowerType.map((item) => { return { node: item.node, matchingFlows: item.flows.filter((flow) => newSelectedFlows.includes(flow)) } })

    for (let nodeData of nodeLayersMatch) {
      if (nodeData.matchingFlows.length === 0) {
        map.current.setLayoutProperty(nodeData.node, 'visibility', 'none')
        if (!nodeData.node.includes('cpoc')) {
          map.current.setLayoutProperty(`${nodeData.node}_fill`, 'visibility', 'none')
        }
        if (nodeData.node.includes('cpoc')) {
          map.current.setLayoutProperty(`${nodeData.node}_inner`, 'visibility', 'none')
        }
      } else {
        map.current.setLayoutProperty(nodeData.node, 'visibility', 'visible')
        if (!nodeData.node.includes('cpoc')) {
          map.current.setLayoutProperty(`${nodeData.node}_fill`, 'visibility', 'visible')
        }
        if (nodeData.node.includes('cpoc')) {
          map.current.setLayoutProperty(`${nodeData.node}_inner`, 'visibility', 'visible')
        }
      }
    }

    //switch on/off icons related to a particular grid
    const iconLayersMatch = iconsPowerType.map((item) => { return { layerId: item.layerId, matchingFlows: item.flows.filter((flow) => newSelectedFlows.includes(flow)) } })
    for (let iconLayerData of iconLayersMatch) {
      map.current.setLayoutProperty(iconLayerData.layerId, 'visibility', iconLayerData.matchingFlows.length === 0 ? 'none' : 'visible')
    }

    // reset all nodes array to be toggled through node info panel
    const activeNodesBuildings = nodeLayersMatch.filter((item) => item.matchingFlows.length > 0 && !item.node.includes('cpoc')).map((item) => item.node)
    // unselect all Nodes
    for (let node of allNodes) {
      map.current.setPaintProperty(node, 'line-width', placeholderNodes.includes(node) ? Consts.placholder_building_line_width : Consts.default_building_line_width);
    }
    // reset all nodes to nodes relevant for selected grids
    setAllNodes(activeNodesBuildings)
    // reset current node index to zero and highlight respective building
    if (activeNodesBuildings.length > 0) {
      map.current.setPaintProperty(activeNodesBuildings[0], 'line-width', placeholderNodes.includes(activeNodesBuildings[0]) ?
        Consts.selected_placeholder_building_line_width : Consts.selected_building_line_width)
    }
    setCurrentNodeIndex(0)

    // new data structure
    const activeGeneralPanelIndicators = newSelectedFlows.map((item) => generalPanelIndicatorsToFlowMap[item])
    var filteredGeneralInfoData = scenarioData.analytics.generalInfoPanelData.map((dataObj) => {
      return {
        ...dataObj,
        grids: dataObj.grids.filter((gridData) => activeGeneralPanelIndicators.includes(gridData.node))
      }
    })
    setGeneralInfoPanelData(filteredGeneralInfoData)
    setSelectedFlows(newSelectedFlows)
  }

  function toggleIsNodePanelOpen(value) {
    setIsNodePanelOpen(!value);
  }

  return (
    <div>
      <div className="coordinatesbar">
        Longitude: {center_lng} | Latitude: {center_lat} | Zoom: {zoom}
      </div>

      <div className="topRowWrapper">
        <ClockAndLocation name={scenarioName} time={timeStepsAverage.formattedTime} date={timeStepsAverage.formattedDate} sunrise={sunRise} sunset={sunSet} />
        <GridSelector options={gridOptions} defaultValue={projectConfig.animated_flows} onSelect={handleGridSelection} projectConfig={projectConfig} />
      </div>

      <Legend projectConfig={projectConfig} />
      <div className='controlButtonsWrapper'>
        <div className='stopStartBtn' onKeyDown={handleKeyDownForControls} onClick={handleAnimationStart}>
          {isMapReady ? <CiPause1 style={{ fontSize: '0.8em' }} /> : <PiPlay style={{ fontSize: '0.8em' }} />}
        </div>
        <div className='nextBtn' onClick={handleNodeSwitch} onKeyDown={handleKeyDownForControls}>
          <PiArrowArcRightLight style={{ fontSize: '0.8em' }} />
        </div>
      </div>
      <div ref={mapContainer} className="map-container" style={{ height: screenHeight, width: screenWidth }}>
        {map.current !== null && isMapReady && animationComponent({
          animatedItemsDataArr: animatedPointsToLoop[currentIndex],
          screenHeight: screenHeight, screenWidth: screenWidth,
          selectedFlows: selectedFlows
        })}
      </div>
      {map.current !== null && allNodes.length > 0 && <NodeInfoPanel isPanelOpen={isNodePanelOpen} polygonCoord={polygonsObj[allNodes[currentNodeIndex]]}
        handleOpenClose={toggleIsNodePanelOpen}
        panelPosition={projectConfig.node_info_panel_position}
        nodeTimestempData={getNodeAssetDataForTimestep(allNodes[currentNodeIndex], currentIndex, assetsData, assetsConfig, projectConfig)} />}
      {map.current !== null && generalInfoPanelData[currentIndex] && (
        <GeneralInfoPanel
          isPanelOpen={isGeneralPanelOpen}
          generalInfoDataRow={generalInfoPanelData[currentIndex]}
          handleOpenClose={toggleIsGeneralPanelOpen}
          panelPosition={projectConfig.general_info_panel_position}
          projectConfig={projectConfig}
        />
      )}
      <div className="vubEvergiLogoMapWrapper">
          <img src={vub_evergi_logo} alt='vub-evergi-logo' width='134px'/>
      </div>
    </div>
  )
}