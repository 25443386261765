import React from 'react'
import { useLocation } from 'react-router-dom';

const TestPage = () => {
    const pageStyle = {
        widt: "100%",
        height: "100dvh",
        color: "white"
    }
  const location = useLocation();
  const endpoint = location.pathname.split('/').slice(-1);
  const animation = location.state?.animation;
  const scenario = location.state?.scenario;

  return (
    <div style={pageStyle}>
    <div>{scenario}</div>
    <div>{animation}</div>
    </div>
  )
}

export default TestPage